export default class Validations {
    
    static checkEmail(user) {

        const retObj = {};
    
        if(user.personal_email == '' || (user.personal_email).length == 0) {
            retObj.status = false;
            retObj.msg = 'Email field cannot be empty.';
            return retObj;
        }
        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.personal_email))
        {
            retObj.status = false;
            retObj.msg = 'Invalid Email.';
            return retObj;
          //return true;
        }
        else {
            retObj.status = true;
            return retObj;
            //return false;
        }
    }
    /*
    static checkEmail(email) {

        const retObj = {};
    
        if(email.length == 0) {
            retObj.status = false;
            retObj.msg = 'Email field cannot be empty.';
            return retObj;
        }
        if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            retObj.status = false;
            retObj.msg = 'Invalid Email.';
            return retObj;
          //return true;
        }
        else {
            retObj.status = true;
            return retObj;
            //return false;
        }
    }
    */

    static checkPhone(user, minLength) {

        const retObj = {};

        if(user.phone.length == 0) {
            retObj.status = false;
            retObj.msg = 'Phone field cannot be empty.';
            return retObj;
        }
        if(user.phone.length < minLength) {
            retObj.status = false;
            retObj.msg = 'Phone should be valid 10 digit number.';
            return retObj;
            //return false;
        }
        else {
            retObj.status = true;
            return retObj;
            //return true;
        }
    }

    static minPassword(user, minLength) {

        const retObj = {};

        if(user.otp_password.length == 0) {
            retObj.status = false;
            retObj.msg = 'Password field cannot be empty.';
            return retObj;
        }
        if(user.otp_password.length < minLength) {
            retObj.status = false;
            retObj.msg = 'Password should be valid '+minLength+' digit number.';
            return retObj;
            //return false;
        }
        else {
            retObj.status = true;
            return retObj;
            //return true;
        }
    }

    static checkName(user) {

        const retObj = {};

        if(user.full_name == '' || user.full_name.length == 0) {
            retObj.status = false;
            retObj.msg = 'Name cannot be empty.';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }

    static checkGender(user) {

        const retObj = {};

        if(user.gender == '' || user.gender == undefined) {
            retObj.status = false;
            retObj.msg = 'Gender cannot be empty.';
            return retObj;
        }
        else if(user.gender.length == 0) {
            retObj.status = false;
            retObj.msg = 'Gender cannot be empty.';
            return retObj;
        }        
        else {
            retObj.status = true;
            return retObj;
        }
    }

    static checkDOB(user) {

        const retObj = {};
        const age = 0;

        if(user.dob == '' || user.dob.length == 0) {
            retObj.status = false;
            retObj.msg = 'Date of Birth cannot be empty.';
            return retObj;
        } 
        if(user.dob.length > 0) {
            let dob = new Date(user.dob);
            let today = new Date();
            let age = Math.floor((today-dob)/(365.25*24*60*60*1000));
            retObj.status = (age > 18) ? true : false;
            retObj.msg =  (retObj.status == true) ? '' :  `Sorry you are not eligible for the participation.`;
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }

    static checkAcadLevel(user) {

        const retObj = {};   
        if(user.degree_type == '' || user.degree_type == undefined) {
            retObj.status = false;
            retObj.msg = 'Select academic level';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }

    static checkDegreeStatus(user) {

        const retObj = {};   
        if(user.degree_status == '' || user.degree_status == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your education status';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }   
    
    static checkGradYear(user) {

        const retObj = {};   
        if(user.graduation_year == '' || user.graduation_year == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your graduation year';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }  
    
    static checkCurrStatus(user) {

        const retObj = {};   
        if(user.current_status == '' || user.current_status == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your current status';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }   
    
    static checkEduMode(user) {

        const retObj = {};   
        if(user.education_mode == '' || user.education_mode == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your education mode';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }  
    
    static checkStudyCountry(user) {

        const retObj = {};   
        if(user.country_of_study == '' || user.country_of_study == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your country of study';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }  
    
    static checkCourseType(user) {

        const retObj = {};   
        if(user.course_type == '' || user.course_type == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your course type';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }
    
    static checkInstitueName(user) {

        const retObj = {};   
        if(user.institute_name == '' || user.institute_name == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your institute name';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    } 

    static checkStuyCity(user) {

        const retObj = {};   
        if(user.city_of_study == '' || user.city_of_study == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your city of study';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    } 

    static checkResiCountry(user) {

        const retObj = {};   
        if(user.residence_country == '' || user.city_ofresidence_countrystudy == undefined) {
            retObj.status = false;
            retObj.msg = 'Select your residence country';
            return retObj;
        }
        else {
            retObj.status = true;
            return retObj;
        }
    }     
}