<template>
  <div v-if="loading">
    <Loader/>
  </div>
  <div v-else>
    <section class="profile-banner"></section>
    <section class="profile-card-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9 col-lg-12">
            <div class="card profile-card">
              <div class="card-head d-flex justify-content-center">
                <div class="absolute-tag">
                  <div class="user-tag">Influencer</div>
                </div>
                <div class="user-info text-center">
                  <div class="user-pic mx-auto"><span v-if="avatar != ''"><img :src="avatar" alt="User" class="w-100" style="border-radius: 50%;"></span></div>
                  <div class="user-category mx-auto fw-medium">{{ status }}</div>
                  <div class="user-name fw-semibold mt-3 mb-2">{{ fullName }}</div>
                  <p class="opacity-75 mb-2">Age: {{ dob }}</p>
                  <p class="opacity-75 mb-2"><img src="@/assets/images/profile/email-icon.svg" alt="Email" class="me-2"><a href="mailto:raj@gmail.com" class="text-dark text-decoration-none">{{ email }}</a> &nbsp;&nbsp;&nbsp; <span v-if="phone != 'null'"><img src="images/profile/phone-icon.svg" alt="Phone" class="me-2"><a href="tel:9923568741" class="text-dark text-decoration-none">+91 99235 68741</a></span></p>
                  <div v-if="avatar !=''">
                    <button class="linkedin-btn mx-auto"><img src="@/assets/images/profile/linkedin-icon.svg" alt="linkedIn">LinkedIn Verified</button>
                  </div>
                  <div v-else>
                    <button @click="redirectToLinkedIn" class="btn btn-yellow px-3">Connect with LinkedIn</button>
                  </div>

                  <div class="profile-card-body">
                    <div class="row">
                      <div class="col-12">
                        <p class="fw-semibold mb-2">About Me</p>
                        <p class="fs-16">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris Adipiscing.</p>
                      </div>
                      <div class="col-12 col-lg-4 my-2 my-lg-4">
                        <p class="fw-semibold mb-2">Interests</p>
                        <p class="fs-16">Eiusmod tempor incididunt</p>
                      </div>
                      <div class="col-12 col-lg-4 my-2 my-lg-4">
                        <p class="fw-semibold mb-2">Career Aspiratons</p>
                        <p class="fs-16">Consectetur adipiscing elit</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-lg-4">
                        <p class="fw-semibold mb-2">University</p>
                        <p class="fs-16">{{ user_university }}</p>
                      </div>
                      <div class="col-12 col-lg-4">
                        <p class="fw-semibold mb-2">Course</p>
                        <p class="fs-16">{{ user_course }}</p>
                      </div>
                      <div class="col-12 col-lg-4">
                        <p class="fw-semibold mb-2">Country</p>
                        <p class="fs-16">{{ user_studycountry }}</p>
                      </div>
                    </div>
                  </div>                  
                  
                  <div>
                      <div class="pp-card h-100">
                        <div class="pp-head fw-semibold">Pocket Perks Points Summary </div>
                        <div class="user-points pp-points position-relative d-flex my-4">
                          <div class="points-text">TOTAL POINTS: </div>
                          <div class="user-score ps-2">{{ flyy_score }}</div>
                        </div>
                        <ul class="pp-ul mt-5">
                          <li v-for="item in items">
                            <div class="d-flex">
                              <div class="pp-dollar">{{item.value}}</div>
                              <p class="mb-0 px-3 mt-2">{{item.reason}}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> 
                </div>
                <div class="user-points">
                  <div class="points-text">POINTS</div>
                  <div class="user-score">{{ flyy_score }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import axios from 'axios';
  import Loader from '@/helpers/Loader.vue';

  let api_url = (process.env.VUE_APP_ENV == 'local') ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_PROD_API;

  function calculateAge(date) {
    let formattedDate = date.split("-")
    let birthdateTimeStamp = new Date(formattedDate[0], formattedDate[1], formattedDate[2])
    let currentDate = new Date().getTime();
    let difference = currentDate - birthdateTimeStamp;
    let currentAge = Math.floor(difference / 31557600000)
    // dividing by 1000*60*60*24*365.25
    return currentAge
  }

  function getAvatar(data) {

    let avatar_image = '';
    if(data != 'null') {
      let jsonobj = JSON.parse(data);
      avatar_image = jsonobj.avatar;
    }
    return avatar_image;
  }

  export default {
    name: 'Profile',
    components: {
      Loader
    }, 
    data() {
      return {
        loading: false,
        avatar: getAvatar(sessionStorage.getItem('user_linkedin')),
        user_university: sessionStorage.getItem('user_university'),
        user_course: sessionStorage.getItem('user_course'),
        user_studycountry: sessionStorage.getItem('user_studycountry'),
        //profileImg: "https://noonewillevergetthisbucketname.s3.amazonaws.com/profile-photos/hero.png",
        fullName: sessionStorage.getItem('username'),
        email: sessionStorage.getItem('useremail'),
        dob: calculateAge(sessionStorage.getItem('userdob')),
        phone: sessionStorage.getItem('userphone'),
        status: (sessionStorage.getItem('user_status') == 'Working') ? 'Alumini' : 'Student',
        items: [],
        currentPage: 1,
        lastPage: 1,
        flyy_score: 0
      };
    },
    mounted () {

      var data = {
        package_name: "com.auxilo.app",
        partner_id: "37d82da065fa1966993d",
        ext_user_token: sessionStorage.getItem('fly_api_token'),
        device_id: sessionStorage.getItem('device_id'),
        environment: "STAGE"
      };
      alert('Device ID: '+data.device_id);
      alert('Ext User Token: '+data.ext_user_token);
      // console.log(data);
      var flyySDK = new FlyySDK();
      flyySDK.initSDK(JSON.stringify(data));
      flyySDK.startReferralTracking();

      let flyyPointsArray = {
        user_id: sessionStorage.getItem('user_id')
      };

      this.fetchItems(flyyPointsArray);
    },

    methods: {
      redirectToLinkedIn() {
        axios.get(`${api_url}/api/linkedin?user_id=${sessionStorage.getItem('user_id')}`)
            .then(response => {
                window.location.href = response.data.data;
          })
          .catch(error => {
            console.error('Error fetching LinkedIn authorization URL:', error);
        });
      },

      async fetchItems(flyarr){
        this.loading = true;
        try {
          axios.post(`${api_url}/api/flyy-points`, flyarr).then(({data})=>{
            this.items = data.transactions;
            this.flyy_score = data.data;
            this.loading = false;
          });
        }
        catch(error) {
          console.error('Error fetching items:', error);
        }
      } 
    }
  }
  </script>