<template>
  <section class="pr1">
    <img src="@/assets/images/connecting-indians/TIS_Logo.svg" alt="TIS Logo" class="TIS-inner-logo">
    <div class="container text-center text-lg-start">
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="cs-title-text mt-3 mt-lg-5">
            <span class="orange-text">Presenting</span>
            to you the
            <span class="green-text">winners </span>of the <span class="blue-text">January Raffle, 2023</span>
          </div>

        </div>
        <div class="col-lg-8 text-center mt-3 mt-sm-0">
          <img src="@/assets/images/winners/Raffle-winner.webp" alt="Masthead Banner" class="img-fluid minus-img ">
        </div>
      </div>
    </div>
  </section>

  <!-- Sec 2 -->
  <section class="pad-100 pr1">
    <img src="@/assets/images/connecting-indians/left-circle.svg" alt="circle" class="left-circle position-absolute">
    <img src="@/assets/images/connecting-indians/line-3.svg" alt="Lines" class="line-right position-absolute">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
            <div class="winner-table">
              <!--
              <div class="row border-bottom" v-for="name in names" :key="name.id">
                <div class="col-2 text-center">
                  <img :src="`@/assets/images/winners/${name.id}.svg`" width="50" alt="" class="w1">
                </div>
                <div class="col-10">
                  <h4>{{ name.name }}</h4>
                </div>                
              </div>
``            -->
              <div class="row border-bottom">
                  <div class="col-2 text-center">
                      <img src="@/assets/images/winners/1.svg" width="50" alt="" class="w1">
                  </div>
                  <div class="col-10">
                      <h4>Sandesh Gharatkar</h4>
                  </div>
              </div>
              <!-- / -->
              <div class="row border-bottom">
                  <div class="col-2 text-center">
                      <img src="@/assets/images/winners/2.svg" width="50" alt="" class="w1">
                  </div>
                  <div class="col-10">
                      <h4>Ashwini Patil</h4>
                  </div>
              </div>
              <!-- / -->
              <div class="row">
                  <div class="col-2 text-center">
                      <img src="@/assets/images/winners/3.svg" width="50" alt="" class="w1">
                  </div>
                  <div class="col-10">
                      <h4>Pankaj Parmar</h4>
                  </div>
              </div>
                <!-- / -->
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*
  const duration = 60 * 60 * 1000,
	animationEnd = Date.now() + duration,
	defaults = { startVelocity: 10, spread: 50, ticks: 2, zIndex: 0 };

  function randomInRange(min, max) {
	  return Math.random() * (max - min) + min;
  }

  const interval = setInterval(function () {
	  const timeLeft = animationEnd - Date.now();

	  if (timeLeft <= 0) {
		  return clearInterval(interval);
	  }

	  const particleCount = 3 * (timeLeft / duration);

	  // since particles fall down, start a bit higher than random
	  confetti(
		  Object.assign({}, defaults, {
			  particleCount,
			  origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
		  })
	  );

	  confetti(
		  Object.assign({}, defaults, {
			  particleCount,
			  origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
		  })
	  );
  }, 250);
*/
  import { ref } from 'vue';
  export default {
    name: 'Winner',
    data () {
      return {
        names: [
          { id: 1, name: 'Sandesh Gharatkar' },
          { id: 2, name: 'Ashwini Patil' },
          { id: 3, name: 'Pankaj Parmar' }
        ]
      }
    },
    setup () {

    },
    mounted () {

    },
    methods: {

    }
  }
</script>
<style scoped>
.winner-table{
  background-color: #2E3BBC;
  padding: 10px;
  border-radius: 15px;
  position: relative;
}
.winner-table h4{
  margin-bottom: 0;
  color: #ffffff;
}
.winner-table .row{
  align-items: center;
  padding: 15px 0;
}
#confetti{
  opacity: .9;
}
.pr1{
  position: relative;
  z-index: 1;
}
.TIS-inner-logo {
  max-width: 250px !important;
}
@media(max-width:576px){        
  .winner-table h4{
    font-size: 16px;
  }
  .w1{
      width: 30px;
  }
  .cs-title-text {
    font-size: 26px;
    line-height: 37px;
  }
  .pad-100{
    padding-top: 20px;
  }
}
</style>