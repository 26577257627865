<template>
    <div v-if="loading">
        <Loader/>
    </div>
    <div v-else>
        <section class="pb-4">
            <div class="container mt-4 mt-sm-0 text-center text-lg-start">
                <div class="row flex-column-reverse flex-lg-row">
                    <div class="col-lg-5">
                        <div class="position-relative">
                            <img src="@/assets/images/overseas/TISP.svg" alt="" class="img-fluid float">
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-6">
                        <h2 class="cs-h2-text"><span class="orange-text">Hi {{ userObj.full_name }} ,</span></h2>
                        <p class="fs-18"><span class="fw-medium">We are proud to have you as our</span> <span class="blue-text fw-bold"> {{userObj.id}}{{nth(userObj.id)}} member</span>, you are not only becoming part of a community but also empowering the next Indian student!</p>
                        <p class="fs-18">Your journey carries great significance and can provide valuable insights to other students venturing into their study abroad experiences.</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Raffle -->
        <section class="position-relative">
            <img src="@/assets/images/overseas/left-circle-yellow.svg" alt="circle" class="left-circle position-absolute">
            <img src="@/assets/images/connecting-indians/line-3.svg" alt="Lines" class="line-right position-absolute">
            <div class="container raffle mt-4 text-center text-lg-start">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-3 col-sm-6 col-6 text-center order-lg-1 order-2">
                        <div class="numbers oswald">{{ remaining_days }}</div><br>
                        <h3 class="fs-30 oswald text-uppercase mt-2 box-after d-inline-block"><span class="blue-text">Days Remaining</span></h3>
                    </div>
                    <div class="col-lg-3 col-sm-12 col-7 text-center order-lg-2 order-1">
                        <img src="@/assets/images/overseas/Raffle.svg" alt="" class="img-fluid">
                    </div>
                    <div class="col-lg-3 col-sm-6 col-6 text-center order-lg-3 order-3">
                        <div class="numbers oswald"> {{ user_survey_completion_count }} /{{ user_survey_count }}</div><br>
                        <h3 class="fs-30 oswald text-uppercase mt-2 box-after d-inline-block"><span class="blue-text">Surveys Completed</span></h3>
                    </div>
                </div>
            </div>
        </section>

        <!-- Walmart -->
        <section class="position-relative mt-5">
            <div class="container">
                <div class="row g-0 justify-content-center voucher-box-new text-center flex-lg-row flex-column-reverse">
                    <div class="col-lg-4 text-center align-items-center left-box d-flex justify-content-center flex-column">
                        <img src="@/assets/images/overseas/voucher.png" width="340" alt="" class="img-fluid Walmart_logo">
                    <!--
                        <img src="@/assets/images/overseas/Walmart_logo.svg.svg" width="340" alt="" class="img-fluid Walmart_logo">
                        <img src="@/assets/images/overseas/dance.svg" alt="" width="200" class="img-fluid">
                    -->
                    </div>
                    <div class="col-lg-4 col-xl-3 right-box">
                        <div class="fs-30 oswald text-uppercase text-white d-flex flex-column justify-content-center h-100">You can win a<br>
                            <span class="yellow-text">Amazing voucher</span> for up to <span class="yellow-text">$100</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Sec 2 -->
        <section class="mt-5 position-relative">
            <div class="container position-relative">
                <img src="@/assets/images/overseas/dott.svg" alt="dot" class="dottt position-absolute">
                <div class="card guide-card">
                    <div class="cs-h2-text text-center yellow-text">Survey Dashboard</div>
                    <div class="row mt-4 mt-lg-5">

                        <div v-for="survey in userSurveyObj" class="col-lg-6 mb-4">
                            <div class="d-flex flex-column flex-xl-row justify-content-between px-0 px-sm-3">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/scholarship/dot-img.svg" width="12" alt="" class="img-fluid dot-12">
                                    <h5 class="ms-2 mb-0 text-white oswald">{{ survey.survey_name }}</h5>
                                </div>
                                <!--div class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3"><img src="@/assets/images/overseas/completed.svg" alt="" width="26"> Completed</a></div-->
                                <div v-if="survey.user_survey_status=='complete'" class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3"><img src="@/assets/images/overseas/completed.svg" alt="" width="26"> Completed</a></div>
                                <div v-else class="rbtn mt-2 mt-xl-0"><a href="#" @click.prevent="opentypeform(survey.form_id, userObj.full_name, userObj.personal_email, userObj.id, surveyObj.course, surveyObj.uni_name, surveyObj.camp, surveyObj.crse_stat, surveyObj.crnt_stat, surveyObj.crse_dt, surveyObj.uni_cntry, surveyObj.camp_cntry, surveyObj.camp_cty, surveyObj.crs_yr_cmpltd, surveyObj.dgre, survey.survey_id)" class="btn btn-yellow px-3"><img src="@/assets/images/overseas/Pending.svg" alt="" width="26"> Start</a></div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <div v-if="userObj.linkedin == null">
                                <button @click="redirectToLinkedIn" class="btn btn-yellow px-3">Connect with LinkedIn</button>
                            </div>
                        </div>
                    
                    <!--
                        <div class="col-lg-6 mb-4">
                            <div class="d-flex flex-column flex-xl-row justify-content-between px-0 px-sm-3">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/scholarship/dot-img.svg" width="12" alt="" class="img-fluid dot-12">
                                    <h5 class="ms-2 mb-0 text-white oswald">India University Review</h5>
                                </div>
                                <div class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3" type="button"><img src="@/assets/images/overseas/inprocess.svg" alt="" width="26"> Resume</a></div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <div class="d-flex flex-column flex-xl-row justify-content-between px-0 px-sm-3">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/scholarship/dot-img.svg" width="12" alt="" class="img-fluid dot-12">
                                    <h5 class="ms-2 mb-0 text-white oswald">Counsellor Review</h5>
                                </div>
                                <div class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3" type="button"><img src="@/assets/images/overseas/Pending.svg" alt="" width="26"> Start</a></div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <div class="d-flex flex-column flex-xl-row justify-content-between px-0 px-sm-3">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/scholarship/dot-img.svg" width="12" alt="" class="img-fluid dot-12">
                                    <h5 class="ms-2 mb-0 text-white oswald">Education Loan Review</h5>
                                </div>
                                <div class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3" type="button"><img src="@/assets/images/overseas/Pending.svg" alt="" width="26"> Start</a></div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <div class="d-flex flex-column flex-xl-row justify-content-between px-0 px-sm-3">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/scholarship/dot-img.svg" width="12" alt="" class="img-fluid dot-12">
                                    <h5 class="ms-2 mb-0 text-white oswald">Scholarship Review</h5>
                                </div>
                                <div class="rbtn mt-2 mt-xl-0"><a class="btn btn-yellow px-3" type="button"><img src="@/assets/images/overseas/Pending.svg" alt="" width="26"> Start</a></div>
                            </div>
                        </div>
                    -->
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Loader from '@/helpers/Loader.vue';

let api_url = (process.env.VUE_APP_ENV == 'local') ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_PROD_API;

export default {
    name: 'Survey',
    components: {
        Loader
    },    
    data () {
        return {
            loading: false,
            userObj: {},
            surveyObj: {},
            userSurveyObj: {},
            user_survey_count: 0,
            user_survey_completion_count: 0,
            remaining_days: this.getRemainingDays()
        }
    },
    created () {
        console.log('Survey page created.');
    },
    mounted () {

        let qs = this.$route;
        this.surveyObj = qs.query;

        var data = {
            package_name: "com.auxilo.app",
            partner_id: "37d82da065fa1966993d",
            ext_user_token: sessionStorage.getItem('fly_api_token'),
            device_id: sessionStorage.getItem('device_id'),
            environment: "STAGE"
        };
        alert('Device ID: '+data.device_id);
        alert('Ext User Token: '+data.ext_user_token);
        // console.log(data);
        var flyySDK = new FlyySDK();
        flyySDK.initSDK(JSON.stringify(data));
        flyySDK.startReferralTracking();
        // console.log('Survey page mounted with params', qs);
        // console.log('first survey', qs.query.first_survey);
        if(qs.query.first_survey=='true') {
            // console.log('gotcha here...');
            //this.opentypeform(qs.query.form_id, qs.query.user_name, qs.query.user_id, qs.query.survey_id);
            this.opentypeform(qs.query.form_id, qs.query.usr_nme, qs.query.usr_email, qs.query.user_id, qs.query.course, qs.query.uni_name, qs.query.camp, qs.query.crse_stat, qs.query.crnt_stat, qs.query.crse_dt, qs.query.uni_cntry, qs.query.camp_cntry, qs.query.camp_cty, qs.query.crs_yr_cmpltd, qs.query.dgre, qs.query.survey_id);
        }
        else {
            this.getUserDetails();
        }
                },
    methods: {

        redirectToLinkedIn() {
            axios.get(`${api_url}/api/linkedin?user_id=${sessionStorage.getItem('user_id')}`)
            .then(response => {
                window.location.href = response.data.data;
            })
            .catch(error => {
                console.error('Error fetching LinkedIn authorization URL:', error);
            });
        },
        async getUserDetails() {
            this.loading = true;
            try {
                //await axios.get('https://theindianstudent.com:8000/api/user', { 
                await axios.get(`${api_url}/api/user`, { 
                    headers: {
                        //Authorization: 'Bearer '+localStorage.getItem('usertoken')
                        Authorization: 'Bearer '+sessionStorage.getItem('usertoken')
                    }
                }).then((response)=>{
                    // console.log('user details..',response.data);
                    let username = response.data.full_name;
                    //localStorage.setItem('username', username);
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('useremail', response.data.personal_email);
                    sessionStorage.setItem('userdob', response.data.dob);
                    sessionStorage.setItem('userphone', response.data.phone);
                    sessionStorage.setItem('user_linkedin', response.data.linkedin);
                    this.userObj = response.data;
                    //console.log('userObj....', userObj);
                    this.getUserSurvey(this.userObj.id);
                });
            }
            catch (error) {
                // console.log(error);
            }
        },
        //opentypeform(survey_formid, user_name, user_id, survey_id) {
        opentypeform(survey_formid, user_name, user_email, user_id, course, university, campus, course_status, current_status, education_mode, country_of_study, campus_country, campus_city, course_completed_year, degree_type, survey_id) {
            //typeformEmbed.makePopup("https://theindianstudent.typeform.com/to/"+survey_formid+"#name="+user_name, {
            typeformEmbed.makePopup("https://theindianstudent.typeform.com/to/"+survey_formid+"#usr_nme="+user_name+"&user_id="+user_id+"&course="+course+"&uni_name="+university+"&camp="+campus+"&crse_stat="+course_status+"&crnt_stat="+current_status+"&crse_dt="+education_mode+"&uni_cntry="+country_of_study+"&camp_cntry="+campus_country+"&camp_cty="+campus_city+"&crs_yr_cmpltd="+course_completed_year+"&dgre="+degree_type, {
                //mode: "drawer_right",
                open: "load",
                autoClose: 3, //close form # seconds after submission
                hideScrollbars: true,
                onSubmit: function() {
                    // console.log("Typeform successfully submitted");
                    // console.log("user_id and survey_id",user_id, survey_id);
                    // console.log("api_url: ",api_url);
                    const cookieString = document.cookie;
                    const cookies = cookieString.split(';').reduce((cookiesObj, cookie) => {
                    const [key, value] = cookie.split('=').map(item => item.trim());
                        if (key && value) {
                            cookiesObj[key] = value;
                        }
                        return cookiesObj;
                    }, {});

                    let usersurvey = {
                        user_id: user_id,
                        user_name: user_name,
                        user_email: user_email,
                        survey_id: survey_id,
                        subscription_id:cookies._nv_push_subscribe,
                        status: ''
                    };


                    //axios.post('https://theindianstudent.com:8000/api/updatesurveystatus', usersurvey).then(async ({data})=>{
                    axios.post(`${api_url}/api/updatesurveystatus`, usersurvey).then(async ({data})=>{
                        // console.log('gotcha...',data);
                        if(data.success) {
                            // console.log('reloading....');
                            //usersurvey.status = 'complete';
                            //this.userSurveyObj = usersurvey;
                            window.location.href = '#/survey';
                            location.reload();
                            //location.replace('#/survey');
                            //window.location.origin = '#/survey';
                            //this.pageReload();
                            //const router = useRouter();
                            //await router.push({ path: '#/survey' });
                            //router.go();
                        }
                    });
                },
                onReady: function() {
                    // console.log("Typeform is ready");
                },
                onClose: function() {
                    // console.log("Typeform is closed");
                }
            });
        },
        async getUserSurvey(user_id) {
            console.log(`user id: ${user_id}`);
            try {
                //await axios.post('https://theindianstudent.com:8000/api/getusersurvey', {user_id: user_id}).then(({data})=>{
                    await axios.post(`${api_url}/api/getusersurvey`, {user_id: user_id}).then(({data})=>{
                        console.log(`survey data: ${data}`);
                    if(data.success) {
                        this.userSurveyObj = data.data;
                        console.log('user survey in success.',this.userSurveyObj);
                        let counter = 0;
                        let complete_ctr = 0;
                        $.each(data.data, function(i, v){
                            if(v.user_survey_status == 'complete') {
                                complete_ctr++;
                            }
                            counter++;
                            // console.log(i,'====',v);
                        });
                        this.user_survey_count = counter;
                        this.user_survey_completion_count = complete_ctr;
                        console.log('total count.',this.user_survey_count); 
                        console.log('user survey count.',this.user_survey_completion_count);
                        this.loading = false;
                    }
                });

                // await axios.post(`${api_url}/api/flyy-user-event`, {user_id: user_id,event_key:"take_a_survey",referal_key:FlyySDK.getCookie("flyy_utm_referrer")}).then(({data})=>{
                //     console.log(data);
                // });
                
                const cookieString = document.cookie;
                const cookies = cookieString.split(';').reduce((cookiesObj, cookie) => {
                const [key, value] = cookie.split('=').map(item => item.trim());
                    if (key && value) {
                        cookiesObj[key] = value;
                    }
                    return cookiesObj;
                }, {});
                let subscriptionId = cookies._nv_push_subscribe;
                if(subscriptionId != undefined){
                    await axios.post(`${api_url}/api/send-notification`, {user_id: user_id,subscription_id:subscriptionId}).then(({data})=>{
                        console.log(data);
                    });
                } 

            } catch (error) {
                console.log(error);
            }
        },
        nth(n) {
            return ["st","nd","rd"][((n+90)%100-10)%10-1]||"th";
        },
        getRemainingDays() {
            let date = new Date();
            let time = new Date(date.getTime());
            time.setMonth(date.getMonth() + 1);
            time.setDate(0);
            let days =time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
            return days;
        },
        async pageReload() {
            const router = useRouter();
            await router.push({ path: '#/survey' });
            router.go();
        },
                    

    } 
}
</script>