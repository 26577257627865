<template>
  <section class="home-banner sec-pad">
    <!--<img src="@/assets/images/home/faq-pattern.svg" alt="pattern" class="banner-pattern position-absolute" style="max-width: 425px;">-->
    <div class="container">
      <div class="card form-card border-0" style="min-height: 100%;">
        <div class="row justify-content-center align-items-center text-center">
          <div class="col-lg-6 col-9 text-center mb-4 mb-lg-0">
            <img src="@/assets/images/Login.png" alt="Login" class="img-fluid">
          </div>
          <div class="col-lg-6">
            <form id="login" @submit.prevent="LoginData()">
              <div id="step1">
                <div class="text-center">
                    <h1>Login</h1><span class="purple"></span>
                    <p class="opacity-75 mt-3">Please enter your email address and OTP sent on your registered email ID.</p>
                </div>
                <div class="alert alert-danger" v-if="error">{{ error }}</div>
                <div class="form-check mb-3 mt-4">
                    <div class="input-group">
                      <input type="email" name="personal_email" v-model.trim="user.personal_email" class="form-control" placeholder="Email ID">
                    </div>
                    <div class="error" v-if="errors.email">{{ errors.email }}</div>
                </div>
                <div class="form-check mb-2 mt-4">
                    <div class="input-group">
                      <input type="password" name="otp_password" v-model.trim="user.otp_password" class="form-control" id="" placeholder="OTP">
                    </div>
                    <div class="error" v-if="errors.password">{{ errors.password }}</div>
                    <br>
                    <div>
                      <button class="btn btn-main px-4 py-2">Login</button>
                      <p class="mt-3"><small><a href="#/login" class="resendotp">Resend OTP</a></small></p>
                    </div>
                </div>
              </div>
            </form>    
            <form id="resendotp" @submit.prevent="resendOTP()">
              <div id="step1">
                <div class="text-center">
                    <h1>Resend OTP</h1><span class="purple"></span>
                    <p class="opacity-75 mt-3">Please enter your registered email address to send the OTP.</p>
                </div>
                <div class="alert alert-danger" v-if="error">{{ error }}</div>
                <div class="form-check mb-3 mt-4">
                    <div class="input-group">
                      <input type="email" name="personal_email" v-model.trim="user.personal_email" class="form-control" placeholder="Email ID">
                    </div>
                    <div class="error" v-if="errors.email">{{ errors.email }}</div>
                </div>
                <div class="form-check mb-2 mt-4">
                    <div>
                      <button class="btn btn-main px-4 py-2">Resend OTP</button>
                    </div>
                </div>
              </div>
            </form>                
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import LoginValidations from '@/services/LoginValidations';

let api_url = (process.env.VUE_APP_ENV == 'local') ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_PROD_API;

$(document).ready(function(){

  //onload hide resend otp form
  $('#resendotp').hide();

  $(document).on('click', '.resendotp', function() {
    $('#resendotp').show();
    $('#login').hide();
  });

});

export default {
    name: 'Login',
    data () {
      return {
        result: {},
        user: {
          personal_email: '',
          otp_password: ''
        },
        errors: [],
        error: '',
        isLogged: this.getStatus()
      }
    },
    mounted () {
      $('#resendotp').hide();
      console.log('isLogged in login.vue', this.isLogged);
    },
    methods: {
        async LoginData () {
          console.log('login data.',this.user);
          //let validations = new LoginValidations(this.user.personal_email, this.user.otp_password);
          let validations = new LoginValidations(this.user);
          this.errors = validations.checkValidations();
          //if('email' in this.errors || 'password' in this.errors) {
          if(this.errors.length > 0) {
            return false;
          }
          this.error = '';
          try {
            //await axios.post('https://theindianstudent.com:8000/api/login', this.user).then(({data}) => {
            await axios.post(`${api_url}/api/login`, this.user).then(({data}) => {
              console.log('login api response.',data); debugger;
              if(data.success) {
                //localStorage.setItem('usertoken', data.data.token);
                sessionStorage.setItem('usertoken', data.data.token);
                sessionStorage.setItem('user_id', data.data.user.id);
                sessionStorage.setItem('user_university', data.data.user_academics.institute_name);
                sessionStorage.setItem('user_course', data.data.user_academics.course_type);
                sessionStorage.setItem('user_studycountry', data.data.user_academics.country_of_study);
                sessionStorage.setItem('fly_api_token', data.data.flyy_user_token);
                alert('fly token');
                alert(data.data.flyy_user_token);
                sessionStorage.setItem('device_id', data.data.device_id);
                alert('Device ID: '+data.data.device_id);
                sessionStorage.setItem('nv_user_id', data.data.user.nv_user_id);

                sessionStorage.setItem('username', data.data.full_name);
                sessionStorage.setItem('useremail', data.data.personal_email);
                sessionStorage.setItem('userdob', data.data.dob);
                sessionStorage.setItem('userphone', data.data.phone);
                sessionStorage.setItem('user_linkedin', data.data.linkedin);
                sessionStorage.setItem('user_status', data.data.user_academics.current_status);
                // nv('user',data.data.user.nv_user_id,{name:data.user.full_name});
                        
                let query_obj = { 
                  user_id: data.data.user.id,
                  usr_nme: data.data.user.full_name,
                  course: data.data.user_academics.course_type,
                  uni_name: data.data.user_academics.institute_name,
                  camp: data.data.user_academics.institute_campus,
                  crse_stat: data.data.user_academics.degree_status,
                  crnt_stat: data.data.user_academics.current_status,
                  crse_dt: data.data.user_academics.education_mode,
                  uni_cntry: data.data.user_academics.country_of_study,
                  camp_cntry: data.data.user_academics.country_of_study,
                  camp_cty: data.data.user_academics.city_of_study,
                  crs_yr_cmpltd: data.data.user_academics.graduation_year,
                  dgre: data.data.user_academics.degree_type,
                };

                this.$router.push(
                  { 
                    //name: 'Survey'
                    path: 'survey' 
                    //query: query_obj 
                  }
                ).then(() => { this.$router.go() });
              }
            }).catch( (error) => {
              // console.log(error);
              if (error.response && error.response.status === 401) {
                this.error = 'Wrong email or password entered.';
              }
            });
          }
          catch(e) {
              this.error = e;
          }
        },
        async resendOTP() {
          try{
            delete this.user.otp_password;  
            //await axios.post('https://theindianstudent.com:8000/api/sendotp', this.user).then(({data}) =>{
            await axios.post(`${api_url}/api/sendotp`, this.user).then(({data}) =>{
              if(data.status) {
                let msg = 'OTP has been sent on your registered email address.';
                toast.info( msg,{ autoClose: 5000 });
                $('#resendotp').hide();
                $('#login').show();
              }
              else {
                this.error = data.message;
              }
            });
          }
          catch(e) {
            this.error = e;
          }
        },
        getStatus() {
            //if(localStorage.getItem('usertoken')) {
            if(sessionStorage.getItem('usertoken')) {
              return true;
            }
            else {
              return false;
            }
        }
    }
}
</script>