<template>
  <!--p>View: {{ view }}</p-->
  <div v-if="loading">
    <Loader/>
  </div>
  <div v-if="view == 'overseas'">
    <Header/>
  </div>
  <router-view :name="view"/>
  <router-view/>
  <Footer/>
<!--
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
-->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import './assets/js/jquery-2.2.4.min.js';
//import './assets/js/popper.min.js';
//import './assets/js/bootstrap.min.js';
//import './assets/js/select2.min.js';
//import './assets/js/slick.min.js';
import Header from '@/views/common/Header.vue';
import Footer from '@/views/common/Footer.vue';
import Loader from '@/helpers/Loader';
import store from '@/composables';
import { computed } from 'vue';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'App',
  setup () {
    const { loading, view, path, init } = store();
    init();
    console.log('composable...', view.value);
    return {
      loading,
      view: computed(() => view.value),
      path
    }
  },
  components: {
    //HelloWorld
    Header,
    Footer
  }
}
</script>
<style>
@import url('./assets/css/bootstrap.min.css');
@import url('./assets/css/custom.css');

#nav{
  /*background: whitesmoke;*/
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: whitesmoke;
  background: crimson;
  border-radius: .5rem;
}
</style>
