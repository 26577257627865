<template>
    <footer class="py-4">
      <div class="container">
        <div class="row align-items-end text-center text-sm-start">
          <div class="col-sm-3">
            <div>
              <img
                src="@/assets/images/Indian-Student-Logo.svg"
                alt=""
                width="170"
              />
            </div>
          </div>
          <div class="col-sm-9 my-4 my-sm-0 d-flex justify-content-end">
            <ul class="tnc-links">
              <!-- Add @click event handlers to router-links -->
              <li>
                <router-link 
                  to="/eula" 
                  class="nav-link"
                  >EULA</router-link>
              </li>
              <li>
                <router-link
                  to="/terms"
                  class="nav-link"
                  @click="redirectTo('/terms')"
                  >Terms and Conditions</router-link
                >
              </li>
              <li>
                <router-link
                  to="/privacypolicy"
                  class="nav-link"
                  @click="redirectTo('/privacypolicy')"
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link
                  to="/disclaimer"
                  class="nav-link"
                  @click="redirectTo('/disclaimer')"
                  >Disclaimer</router-link
                >
              </li>
              <li>
                <router-link
                  to="/cookiepolicy"
                  class="nav-link"
                  @click="redirectTo('/cookiepolicy')"
                  >Cookies Policy</router-link
                >
              </li>
              <li>
                <router-link
                  to="/contestrules"
                  class="nav-link"
                  @click="redirectTo('/contestrules')"
                  >Contest Rules & Regulations</router-link
                >
              </li>
              <li>
                <router-link
                  to="/contactus"
                  class="nav-link"
                  @click="redirectTo('/contactus')"
                  >Contact Us</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <hr style="opacity: 0.15" />
        <div class="row align-items-center text-center text-sm-start">
          <div class="col-sm-8 my-4 my-sm-0">
            <small>© theindianstudent.com | All rights reserved</small>
          </div>
          <div
            class="col-sm-4 d-flex justify-content-center justify-content-md-end"
          >
            <ul class="social-icons">
              <!-- Your social icons links here -->
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "Footer",
    methods: {
      redirectTo(route) {
        // Redirect to the specified route
        this.$router.push(route);
  
        // Reload the page after a delay (e.g., 1 second)
        setTimeout(() => {
          window.location.reload();
        }, 0);
      },
    },
  };
  </script>