<template>
    <div>
        <section class="contest-banner">
            <div class="inner-header d-flex justify-content-between align-items-start">
                <div class="m-4 top-points">
                    <div class="user-points pp-points position-relative d-flex">
                    <div class="points-text">POINTS: </div>
                    <div class="user-score ps-2">8950</div>
                    </div>
                </div>
            </div>
            <div class="container position-relative mt-4 mt-md-0">
                <div class="m-4 d-flex d-lg-none">
                    <div class="user-points pp-points position-relative d-flex">
                    <div class="points-text">POINTS: </div>
                    <div class="user-score ps-2">8950</div>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-5 col-xl-5 col-8 text-center">
                        <img src="@/assets/images/contest/JOIN.svg" alt="Join Our Monthly Photo Contest!" class="img-fluid">
                    </div>
                    <div class="col-lg-6 col-xl-6 col-8 text-center mt-4 mt-lg-0">
                        <img src="@/assets/images/contest/Boy.webp" alt="Cameraman" class="img-fluid">
                    </div>
                </div>
            </div> 
            <img src="@/assets/images/contest/circles.svg" alt="circles" class="position-absolute cb-circles">
            <img src="@/assets/images/contest/contest-text.svg" alt="Contest" class="position-absolute cb-contest-text">           
        </section>
        <h2 class="cb-ybar fw-semibold">Share Your University Moments & Win!</h2>
  <!-- Welcome -->
  <section class="sec-pad mptb-30px position-relative">
    <img src="@/assets/images/contest/ring-right-small.png" alt="Ring" class="position-absolute cb-ring-right">
    <div class="container-fluid px-3 px-md-0">
      <div class="row">
        <div class="col-lg-5">
          <img src="@/assets/images/contest/camera.webp" alt="Camera" class="img-fluid d-none d-lg-flex">
        </div>
        <div class="col-lg-7">
          <div class="px-100">
            <div class="cb-h3 mt-5">Welcome To Our <br><span class="fw-semibold">
                Exciting Monthly Photo Contest</span></div>
            <h4 class="text-uppercase blue-text mt-3 mb-4 fw-semibold">exclusively on The Indian Student (TIS)! </h4>
            <p class="poppins fs-18">Every Month, We Challenge You To Capture Moments That Define Your Study Abroad Journey. It’s Your Chance To Showcase Your Creativity, Share Your Unique Experiences And
              Win Amazing Prizes!</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-n100">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="cb-h3"><span class="fw-semibold blue-text">In Front Of Your</span><br>
            University/College</div>
          <p class="poppins fs-18 mt-4">Capture a moment in front of your university or college that holds special significance to you. It could be your first day, a place you find inspiring, or just your favorite spot on campus.
            <br><br>
            <span class="fw-medium">Add a title and a short description to tell us why this place or moment is special to you.</span>
          </p>
        </div>
        <div class="col-lg-6">
          <img src="@/assets/images/contest/Girl-Photo.webp" alt="Girl Photo" class="img-fluid">
        </div>
      </div>
    </div>
  </section>

  <!-- How to Participate -->
  <section class="sec-pad position-relative">
    <img src="@/assets/images/contest/left-ring-big.png" alt="Ring" class="position-absolute cb-ring-left">
    <div class="container">
      <div class="cb-h3 text-center">How to <span class="fw-semibold blue-text"> Participate</span></div>
      <div class="row mt-5 align-items-center justify-content-center">
        <div class="col-lg-5">
          <div class="card cb-card mb-4">
            <div class="d-flex cb-part">
              <div class="cb-part-img"><img src="@/assets/images/contest/capture-icon.svg" alt="Capture Icon" class="img-fluid"></div>
              <div><span class="fw-semibold blue-text fs-20">Capture:</span> <br>Take a photo that fits this month's theme.</div>
            </div>
          </div>
          <!-- / -->
          <div class="card cb-card mb-4">
            <div class="d-flex cb-part">
              <div class="cb-part-img"><img src="@/assets/images/contest/describe-icon.svg" alt="Describe Icon" class="img-fluid"></div>
              <div><span class="fw-semibold blue-text fs-20">Describe:</span> <br>Write a compelling title and a brief description.</div>
            </div>
          </div>
          <!-- / -->
          <div class="card cb-card mb-4">
            <div class="d-flex cb-part">
              <div class="cb-part-img"><img src="@/assets/images/contest/submit-icon.svg" alt="Submit Icon" class="img-fluid"></div>
              <div><span class="fw-semibold blue-text fs-20">Submit:</span> <br>Upload your photo and descriptions here. </div>
            </div>
          </div>
          <!-- / -->
        </div>
        <div class="col-lg-1"></div>
        <!-- Form - Photo Upload -->
        <div class="col-lg-5">
          <div class="card cb-card pb-3">
            <form id="uploadForm" method="" action="#">
              <label class="picture" for="picture__input" tabIndex="0">
                <span class="picture__image"></span>
                <!-- <img src="images/contest/photo-icon.svg" alt=""> -->
              </label>
              <input type="file" name="picture__input" id="picture__input">

              <div class="form-floating mb-2 cb-floating mt-3">
                <input type="text" class="form-control" id="floatingTitle" placeholder="">
                <label for="floatingTitle">Title</label>
              </div>
              <div class="form-floating cb-floating">
                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" style="height: 80px"></textarea>
                <label for="floatingTextarea">Description</label>
              </div>
            </form>
            <div class="d-block d-lg-flex justify-content-between align-items-center mt-3">
              <div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck">
                  <label class="form-check-label" for="gridCheck">
                    I accept T&C
                  </label>
                </div>
              </div>
              <div class="mt-3 mt-lg-0"><input type="image" value="" src="@/assets/images/contest/submit-btn.png"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Prizes -->
  <section class="sec-pad mptb-30px position-relative">
    <div class="container">
      <img src="@/assets/images/contest/prizes-text.svg" alt="Prizes" class="position-absolute cb-contest-text cb-prize-text">
      <div class="cb-h3 text-center fw-semibold"><span class="blue-text">Prizes</span></div>

      <div class="row justify-content-center text-center">
        <div class="col-lg-3 col-md-4 col-4">
          <img src="@/assets/images/contest/2nd.svg" alt="2nd" class="mt-max img-fluid">
        </div>

        <div class="col-lg-5 col-md-4 col-4">
          <img src="@/assets/images/contest/1st.svg" alt="1st" class="img-fluid mt-5">
          <img src="@/assets/images/contest/trophy.webp" alt="Trophy" class="mt-5 img-fluid d-none d-lg-flex">
        </div>

        <div class="col-lg-3 col-md-4 col-4">
          <img src="@/assets/images/contest/3rd.svg" alt="3rd" class="mt-max img-fluid">
        </div>

        <div class="col-8">
          <img src="@/assets/images/contest/trophy.png" alt="Trophy" class="mt-5 img-fluid d-flex d-lg-none">
        </div>

      </div>
      <h2 class="cb-ybar sm-ybar fw-semibold">Submission Deadline is 31<sup>st</sup> May, 2024</h2>
    </div>
  </section>

  <section class="pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="cb-card">
          <h4 class="mb-2 pb-3 fw-semibold border-bottom">Leaderboard</h4>
            <table class="table cp-table">
              <thead>
                <tr>
                  <td class="col-lg-2">Rank</td>
                  <td class="col-lg-7">User</td>
                  <td class="col-lg-3" align="right">Vote Count</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1st</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>John Doe</div></div></td>
                  <td align="right">116</td>
                </tr>
                <tr>
                  <td>2nd</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Abhilash Pillai</div></div></td>
                  <td align="right">112</td>
                </tr>
                <tr>
                  <td>3rd</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Raj Kumar</div></div></td>
                  <td align="right">111</td>
                </tr>
                <tr>
                  <td>4th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>5th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>6th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>7th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>8th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>9th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
                <tr>
                  <td>10th</td>
                  <td><div class="d-flex align-items-center"><img src="@/assets/images/contest/students.png" alt=""> <div>Mark</div></div></td>
                  <td align="right">98</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sec-pad">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="grey-card">
            <h4 class="mb-4 fw-semibold">Terms & Conditions</h4>
            <ul class="m-0">
              <li>Participants must be currently enrolled in a university or college.</li>
              <li>Each participant can submit one entry per contest.</li>
              <li>Entries must be original and comply with the theme.</li>
              <li>All submissions are subject to approval by our admin team before being displayed.</li>
            </ul>
          </div>
        </div>
      </div>

      <br>
      <div class="cb-h3 h3-sm text-center mt-5">Participate Now<br><span class="blue-text fw-semibold">
          And Make Your University Proud!</span></div>

    </div>
  </section>        
    </div>
</template>
<script>
export default {
    name: 'Contest'
}
</script>