<template>
    <div>
        <section class="contest-banner">
            <div class="inner-header d-flex justify-content-between align-items-start">
                <div class="m-4 top-points">
                    <div class="user-points pp-points position-relative d-flex">
                        <div class="points-text">POINTS: </div>
                        <div class="user-score ps-2">8950</div>
                    </div>
                </div>
            </div>
            <div class="container position-relative mt-4 mt-md-0">
                <div class="m-4 d-flex d-lg-none">
                    <div class="user-points pp-points position-relative d-flex">
                        <div class="points-text">POINTS: </div>
                        <div class="user-score ps-2">8950</div>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-4 col-sm-4 text-center">
                        <img src="@/assets/images/contest/people.svg" alt="People" class="img-fluid d-none d-sm-flex">
                    </div>
                    <div class="col-lg-4 col-sm-4 text-center">
                        <div class="ge-title fw-semibold mb-5">Explore This Month's Contest Entries!</div>
                        <img src="@/assets/images/contest/voting-machine.svg" alt="Voting Machine" class="img-fluid">
                    </div>
                    <div class="col-lg-4 col-sm-4 text-center">
                        <img src="@/assets/images/contest/people.svg" alt="People" class="img-fluid fliphr d-none d-sm-flex">
                    </div>
                </div>
            </div>

            <img src="@/assets/images/contest/lights.svg" alt="lights" class="position-absolute ge-lights">            
        </section>

        <!-- Introduction -->
        <section class="map-bg">

            <div class="container px-3 px-md-0">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-10">
                        <p class="poppins fs-20"><span class="fw-semibold">Dive into the gallery of captivating moments shared by students from around the globe.</span>
                            Each photo tells a unique story of life at university or college, captured through the lenses of our vibrant community members. </p>

                        <h4 class="blue-text mt-5 mb-4 fw-semibold">Explore, get inspired, and don’t forget to <br>
                            vote for your favorite entries!</h4>
                    </div>
                </div>
            </div>

        </section>    
        
        <!-- Gallery -->
        <section class="sec-pad position-relative">
            <img src="@/assets/images/contest/ring-right-small.png" alt="Ring" class="position-absolute cb-ring-right">
            <img src="@/assets/images/contest/left-ring-big.png" alt="Ring" class="position-absolute cb-ring-left">
            <div class="container">
                <div class="row justify-content-center">

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->

                    <div class="col-lg-4 col-sm-6 mb-5">
                        <div class="gal-card">
                            <button class="vote-btn border-0"><img src="@/assets/images/contest/like.svg" alt="Like" class="vote-img"> Vote</button>
                            <img src="@/assets/images/contest/gal1.jpg" alt="">
                            <div class="gal-body">
                                <div class="gal-title fs-18 fw-semibold">Title goes here</div>
                                <div class="gal-desc">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore.</div>
                            </div>
                        </div>
                    </div>
                    <!-- / Col 4 -->
                </div>
                <!-- / Row -->

                <nav class="mt-4" aria-label="Page navigation example">
                    <ul class="pagination justify-content-center gal-pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                            <img src="@/assets/images/contest/prev-btn.svg" alt="">
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">01</a></li>
                        <li class="page-item active"><a class="page-link" href="#">02</a></li>
                        <li class="page-item"><a class="page-link" href="#">03</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <img src="@/assets/images/contest/next-btn.svg" alt="">
                            </a>
                        </li>
                    </ul>
                </nav>

            </div>
        </section>

        <!-- Stay Tuned -->
        <section class="sec-pad pt-0 position-relative">
            <div class="container-fluid ps-0">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-sm-4">
                        <img src="@/assets/images/contest/stay-tuned.svg" alt="Stay Tuned" class="img-fluid">
                    </div>
                    <div class="col-lg-7 col-sm-8">
                        <div class="px-100">
                            <div class="cb-h3 ge-30 fw-semibold ps-3 ps-md-0">Winners will be announced on <span class="blue-text">11th June, 2024</span> on our website and social media channels.</div>            
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="cb-h3 h3-sm text-center mt-5 mt-lg-0">Support Your Peers & Help Us<br><span class="blue-text fw-semibold">
            Find This Month’s Winners!</span></div>

        </section>        
    </div>
</template>
<script>
export default {
    name: 'Gallery'
}
</script>