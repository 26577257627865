import { ref, computed } from 'vue';
import iplocation from '@/api';

export default function store() {
    const view = ref('');
    const loading = ref(false);
    const path = ref(window.location.pathname);
    //console.log('path....',path.value);
    async function init() {
      loading.value = true;
      view.value = 'overseas';//await iplocation();//'indian';
      loading.value = false;
    }
    return {
      view: computed(() => view.value),
      loading: computed(() => loading.value),
      path: computed(() => path.value),
      init
    };
  }