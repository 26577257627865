<template>
    <section>
      <div class="inner-header d-flex justify-content-between">
        <div>
          <router-link to="/" class="nav-item nav-link" @click="redirectToHome">
            <img
              src="@/assets/images/connecting-indians/TIS_Logo.svg"
              alt="TIS Logo"
              class="TIS-inner-logo"
            />
          </router-link>
        </div>
        <div id="nav">
          <nav class="container navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div v-if="!isLogged" class="navbar-nav ml-auto">
                <router-link
                  to="/"
                  class="nav-item nav-link"
                  @click="redirectToHome"
                  >Home</router-link
                >
              </div>
              <div v-if="!isLogged" class="navbar-nav mr-auto">
                <router-link
                  to="/signup"
                  class="nav-link"
                  @click="redirectToSignup"
                  >Signup</router-link
                >
              </div>
              <div v-if="!isLogged" class="navbar-nav mr-auto">
                <router-link to="/login" class="nav-link" @click="redirectToLogin"
                  >Login</router-link
                >
              </div>
              <div v-if="isLogged" class="navbar-nav mr-auto">
                <router-link
                  to="/survey"
                  class="nav-link"
                  @click="redirectToSurvey"
                  >Survey</router-link
                >
              </div>
              <div v-if="isLogged" class="navbar-nav mr-auto">
                <router-link to="/contest" class="nav-link" @click="redirectToContest"
                  >Contest</router-link
                >
              </div>              
              <div v-if="isLogged" class="navbar-nav mr-auto">
                <router-link to="/gallery" class="nav-link" @click="redirectToGallery"
                  >Gallery</router-link
                >
              </div>                            
              <div v-if="isLogged" class="navbar-nav mr-auto">
                <router-link to="/profile" class="nav-link" @click="redirectToProfile"
                  >My Profile</router-link
                >
              </div>
              <div v-if="isLogged" class="navbar-nav mr-auto">
                <a href="/" class="nav-link" @click.prevent="logout()">Logout</a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "Header",
    data() {
      return {
        isLogged: this.getStatus(),
      };
    },
    mounted() {
      console.log("islogged in header", this.isLogged);
    },
    methods: {
      logout() {
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("usertoken");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("userphone");
        sessionStorage.removeItem("userdob");
        sessionStorage.removeItem("useremail");
        sessionStorage.removeItem("user_linkedin");
        sessionStorage.removeItem("user_status");
        sessionStorage.removeItem("user_university");
        sessionStorage.removeItem("user_course");
        sessionStorage.removeItem("user_studycountry");
        this.$router.push({ path: "/" }).then(() => {
          this.$router.go();
        });
      },
      redirectToHome() {
        this.$router.push({ path: "/" }).then(() => {
          this.$router.go();
        });
      },
      redirectToSignup() {
        this.$router.push({ path: "/signup" }).then(() => {
          this.$router.go();
        });
      },
      redirectToLogin() {
        this.$router.push({ path: "/login" }).then(() => {
          this.$router.go();
        });
      },
      redirectToSurvey() {
        this.$router.push({ path: "/survey" }).then(() => {
          this.$router.go();
        });
      },
      redirectToProfile() {
        this.$router.push({ path: "/profile" }).then(() => {
          this.$router.go();
        });
      },
      redirectToContest() {
        this.$router.push({ path: "/contest" }).then(() => {
          this.$router.go();
        });
      },
      redirectToGallery() {
        this.$router.push({ path: "/gallery" }).then(() => {
          this.$router.go();
        });
      },      
      getStatus() {
        if (sessionStorage.getItem("usertoken")) {
          this.username = sessionStorage.getItem("username");
          return true;
        } else {
          return false;
        }
      },
    },
  };
  </script>