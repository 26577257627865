<template>
    <div v-if="loading">
        <Loader/>
    </div>
    <section class="sec-pad pt-0 form-section position-relative">
        <img src="@/assets/images/connecting-indians/left-circle.svg" alt="circle" class="left-circle position-absolute">
        <img src="@/assets/images/connecting-indians/line-3.svg" alt="Lines" class="line-right position-absolute">
        <div class="container">
            <div class="fs-36 text-center oswald text-uppercase mb-5">Your <span class="orange-text">Voice</span> Matters</div>
            <div class="card form-card border-0 ">
                <form @submit.prevent="saveData">
                    <fieldset :disabled="loading">
                        <div class="text-center">
                            <img src="@/assets/images/connecting/Network.svg" alt="Network" class="img-fluid network-icon">
                            <div class="fs-22 oswald text-uppercase mt-2"><span class="blue-text">Sign up, together, let's create a powerful network that shapes and</span><br>uplifts the futures of countless Indian students globally.</div>
                        </div>
                        <div class="card mt-4">
                            <nav class="pt-3">
                                <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button class="nav-link active" id="personal-tab" data-bs-toggle="tab" data-bs-target="#nav-personal" type="button" role="tab" aria-controls="nav-personal" aria-selected="true">Personal Details</button>
                                <button class="nav-link" id="academic-tab" data-bs-toggle="tab" data-bs-target="#nav-academic" type="button" role="tab" aria-controls="nav-academic" aria-selected="false">Academic Details</button>
                                </div>
                            </nav>
                            <div class="tab-content p-3 p-lg-5" id="nav-tabContent">
                                <!-- Personal Details-->
                                <div class="tab-pane fade show active" id="nav-personal" role="tabpanel" aria-labelledby="personal-tab" tabindex="0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group mb-4">
                                                <label class="form-check-label me-3" for="indian_national">Are you an Indian national? <span style="color:red;">*</span></label>
                                                <div class="radio-tab">
                                                    <input name="indian_national" id="opt1" v-model="user.indian_national" type="radio" class="btn-check checked" value="Y" autocomplete="off" :checked="true">
                                                    <label class="btn" for="opt1">Yes</label>

                                                    <input name="indian_national" id="opt2" v-model="user.indian_national" type="radio" class="btn-check" value="N" autocomplete="off">
                                                    <label class="btn" for="opt2">No</label>
                                                </div>
                                            </div>
                                            <div class="indian_national_msg"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group form-floating mb-4">
                                                <input name="full_name" id="full_name" v-model.trim="user.full_name" type="text" class="form-control" placeholder="Full Name" ref="name">
                                                <label for="full_name">Full Name <span style="color:red;">*</span></label>
                                            </div>
                                            <div class="full_name_msg"></div>
                                            <div class="error" v-if="errors.name">{{ errors.name }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group form-floating mb-4">
                                                <!--input name="personal_email" id="personal_email" v-model.trim="user.personal_email" type="email" @keyup="validateField('email')" class="form-control" placeholder="Email ID"-->
                                                <input name="personal_email" id="personal_email" v-model.trim="user.personal_email" class="form-control" placeholder="Email ID">
                                                <label for="personal_email">Email ID<span style="color:red;">*</span></label>
                                            </div>
                                            <div class="personal_email_msg"></div>
                                            <div class="error" v-if="errors.email">{{ errors.email }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group mb-4">
                                                <label for="gender" class="me-3">Gender <span style="color:red;">*</span></label>
                                                <div class="radio-tab">
                                                    <input name="gender" id="option1" v-model="user.gender" type="radio" class="btn-check" value="M" autocomplete="off">
                                                    <label class="btn" for="option1">Male</label>

                                                    <input name="gender" id="option2" v-model="user.gender" type="radio" class="btn-check" value="F" autocomplete="off">
                                                    <label class="btn" for="option2">Female</label>

                                                    <input name="gender" id="option3" v-model="user.gender" type="radio" class="btn-check" value="O" autocomplete="off">
                                                    <label class="btn" for="option3">Other</label>
                                                </div>
                                            </div>
                                            <div class="gender_msg"></div>
                                            <div class="error" v-if="errors.gender">{{ errors.gender }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group form-floating mb-4">
                                                <input name="dob" id="dob" v-model="user.dob" type="date" class="form-control" placeholder="Age">
                                                <label for="dob">Date Of Birth <span style="color:red;">*</span></label>
                                            </div>
                                            <div class="dob_msg"></div>
                                            <div class="error" v-if="errors.dob">{{ errors.dob }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group form-floating mb-5">
                                                <input name="linkedin_profile" id="linkedin_profile" v-model="user.linkedin_profile" type="text" class="form-control" placeholder="LinkedIn Profile ID">
                                                <label for="linkedin_profile">LinkedIn Profile ID</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <!--button class="btn btn-yellow text-dark px-4 py-2 text-uppercase oswald" id="nexttab">Next</button-->
                                            <!--input type="submit" value="Submit" class="btn btn-yellow text-dark"-->
                                            <!--<a href="#/signup" class="btn btn-yellow text-dark px-4 py-2 text-uppercase oswald" id="nexttab">Next</a>-->
                                            <div id="nexttab" class="btn btn-yellow text-dark px-4 py-2 text-uppercase oswald">Next</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Academic Details-->
                                <div class="tab-pane fade" id="nav-academic" role="tabpanel" aria-labelledby="academic-tab" tabindex="0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="degree_type" id="degree_type" v-model="user.degree_type" class="form-select" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="degree_type_msg"></div>
                                            <div class="error" v-if="errors.degree_type">{{ errors.degree_type }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group mb-4">
                                                <label class="form-check-label me-3 mb-4" for="degree_status">What is your current education status? <span style="color:red;">*</span></label>
                                                <div class="radio-tab">
                                                    <input name="degree_status" id="degree_status1" v-model="user.degree_status" type="radio" class="btn-check" value="C" autocomplete="off">
                                                    <label class="btn" for="degree_status1">Completed</label>

                                                    <input name="degree_status" id="degree_status2" v-model="user.degree_status" type="radio" class="btn-check" value="P" autocomplete="off">
                                                    <label class="btn" for="degree_status2">Pursuing</label>
                                                </div>
                                            </div>
                                            <div class="degree_status_msg"></div>
                                            <div class="error" v-if="errors.degree_status">{{ errors.degree_status }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="graduation_year" id="graduation_year" v-model="user.graduation_year" class="form-control" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="graduation_year_msg"></div>
                                            <div class="error" v-if="errors.graduation_year">{{ errors.graduation_year }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="current_status" id="current_status" v-model="user.current_status" class="form-control" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="current_status_msg"></div>
                                            <div class="error" v-if="errors.current_status">{{ errors.current_status }}</div>
                                        </div>
                                        <div class="col-md-12 mb-4">
                                            <p>What is the mode of your education?</p>
                                            <div class="radio-tab">
                                                <input type="radio" id="dt-option2" name="education_mode" v-model="user.education_mode" value="offline" class="btn-check" autocomplete="off">
                                                <label class="btn" for="dt-option2">On Campus</label>
                                                <input type="radio" id="dt-option1" name="education_mode" v-model="user.education_mode" value="online" class="btn-check" autocomplete="off">
                                                <label class="btn" for="dt-option1">Online</label>
                                                <input type="radio" id="dt-option3" name="education_mode" v-model="user.education_mode" value="hybrid" class="btn-check" autocomplete="off">
                                                <label class="btn" for="dt-option3">Blended</label>
                                            </div>
                                            <div class="education_mode_msg"></div>
                                            <div class="error" v-if="errors.education_mode">{{ errors.education_mode }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="country_of_study" id="country_of_study" v-model="user.country_of_study" class="form-control" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="country_of_study_msg"></div>
                                            <div class="error" v-if="errors.current_status">{{ errors.current_status }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="course_type" id="course_type" v-model="user.course_type" class="form-select" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="course_type_msg"></div>
                                            <div class="error" v-if="errors.course_type">{{ errors.course_type }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="institute_name" id="institute_name" v-model.trim="user.institute_name" class="form-control form-select single-select" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="institute_name_msg"></div>
                                            <div class="error" v-if="errors.institute_name">{{ errors.institute_name }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="city_of_study" id="city_of_study" v-model.trim="user.city_of_study" class="form-control form-select single-select" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="city_of_study_msg"></div>
                                            <div class="error" v-if="errors.city_of_study">{{ errors.city_of_study }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-4">
                                                <select name="residence_country" id="residence_country" v-model="user.residence_country" class="form-control" style="width: 100%; border-radius: 2rem;" aria-label="Floating label select example"></select>
                                            </div>
                                            <div class="residence_country_msg"></div>
                                            <div class="error" v-if="errors.residence_country">{{ errors.residence_country }}</div>                                            
                                        </div>                                        
                                        <div class="col-md-12">
                                            <div class="form-check mb-4">
                                                <input type="checkbox" name="tnc_acceptance" id="tnc_acceptance" value="Y" v-model="user.tnc_acceptance" class="form-check-input">
                                                <label class="form-check-label" for="flexCheckDefault"><a href="#/signup" class="tnc">I accept the term and conditions</a></label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <button class="btn btn-yellow text-dark">
                                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                                <span>Signup</span>
                                            </button>
                                            <!--input type="submit" value="Submit" class="btn btn-yellow text-dark"-->
                                            <!--a href="#" class="btn btn-yellow text-dark px-4 py-2 text-uppercase oswald" data-bs-toggle="modal" data-bs-target="#OTPModal">Submit</a-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </section>
    <!-- OTP Modal Begin-->
    <div class="modal fade" id="OTPModal" tabindex="-1" aria-labelledby="OTPModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-4">
                <!--<button type="button" class="btn-close otp-close" data-bs-dismiss="modal" aria-label="Close" style="position: absolute; right: 10px; top: 10px;"></button>-->
                <div class="modal-body text-center">
                    <p class="fs-5">Please enter the <b>OTP</b> sent to your <br>registered <b>Email ID</b> </p>
                    <div class="alert alert-danger errorOTP d-none"></div>
                    <div class="form-floating mb-4">
                        <input name="otp_password" id="otp_password" v-model.trim="user_creds.otp_password" type="text" class="form-control" placeholder="Enter OTP">
                        <label for="floatingOTP">Enter Your OTP</label>
                    </div>
                    <button v-on:click="login" type="button" class="btn btn-yellow text-dark px-4 py-2 text-uppercase oswald">Confirm OTP</button>
                </div>
            </div>
        </div>
    </div>
    <!-- OTP Modal End-->
    <!-- TnC Modal Begin -->
    <div class="modal fade" id="TNCModal" tabindex="-1" aria-labelledby="TNCModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <!--
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-4">
                <button type="button" class="btn-close tnc-close" data-bs-dismiss="modal" aria-label="Close" style="position: absolute; right: 10px; top: 10px;"></button>
                <div class="modal-body text-center">
                    <p class="fs-5">Terms of condition</p>
                </div>
            </div>
        </div>
        -->
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h4 ALIGN="">Terms and Conditions</h4>
                    <a href="#" class="tnc-close" data-bs-dismiss="modal" aria-label="Close"><img src="https://www.auxilo.com/assets/images/close-menu.svg" width="24" height="24"></a>
                </div>
                <div class="modal-body pt-0">
                    <div class="mb-2  row justify-content-center">
                        <div class="col-12">
                            <h1>Terms and Conditions</h1>
                            <p>Last updated: February 16, 2024</p>
                            <p>Please read these terms and conditions carefully before using Our Service.</p>
                            <h2>Interpretation and Definitions</h2>
                            <h3>Interpretation</h3>
                            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                            <h3>Definitions</h3>
                            <p>For the purposes of these Terms and Conditions:</p>
                            <ul>
                                <li><p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p></li>
                                <li><p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p></li>
                                <li><p><strong>Country</strong> refers to: Maharashtra, India</p></li>
                                <li><p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Auxilo Finserve Pvt Ltd, Auxilo Finserve Pvt. Ltd. Office No. 63, 6th floor, Kalpataru Square, Kondivita Road, Andheri East, Mumbai 400059.</p></li>
                                <li><p><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</p></li>
                                <li><p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p></li>
                                <li><p><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</p></li>
                                <li><p><strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.</p></li>
                                <li><p><strong>Service</strong> refers to the Website.</p></li>
                                <li><p><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p></li>
                                <li><p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p></li>
                                <li><p><strong>Website</strong> refers to The Indian Student, accessible from <a href="https://theindianstudent.com/" rel="external nofollow noopener" target="_blank">https://theindianstudent.com</a></p></li>
                                <li><p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
                            </ul>
                            <h2>Acknowledgment</h2>
                            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                            <h2>Promotions</h2>
                            <p>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>
                            <p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
                            <h2>User Accounts</h2>
                            <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
                            <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
                            <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
                            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
                            <h2>Content</h2>
                            <h3>Your Right to Post Content</h3>
                            <p>Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</p>
                            <p>By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</p>
                            <p>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
                            <h3>Content Restrictions</h3>
                            <p>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under Your account, whether done so by You or any third person using Your account.</p>
                            <p>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</p>
                            <ul>
                                <li>Unlawful or promoting unlawful activity.</li>
                                <li>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li>
                                <li>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li>
                                <li>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</li>
                                <li>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</li>
                                <li>Impersonating any person or entity including the Company and its employees or representatives.</li>
                                <li>Violating the privacy of any third person.</li>
                                <li>False information and features.</li>
                            </ul>
                            <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with these Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</p>
                            <h3>Content Backups</h3>
                            <p>Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.</p>
                            <p>Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.</p>
                            <p>The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.</p>
                            <p>You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>
                            <h2>Copyright Policy</h2>
                            <h3>Intellectual Property Infringement</h3>
                            <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</p>
                            <p>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at admin@theindianstudents.com and include in Your notice a detailed description of the alleged infringement.</p>
                            <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</p>
                            <h3>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</h3>
                            <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
                            <ul>
                                <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
                                <li>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
                                <li>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li>
                                <li>Your address, telephone number, and email address.</li>
                                <li>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                                <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                            </ul>
                            <p>You can contact our copyright agent via email at admin@theindianstudents.com. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</p>
                            <h2>Intellectual Property</h2>
                            <p>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</p>
                            <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>
                            <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>
                            <h2>Your Feedback to Us</h2>
                            <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>
                            <h2>Links to Other Websites</h2>
                            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                            <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                            <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
                            <h2>Termination</h2>
                            <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                            <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
                            <h2>Limitation of Liability</h2>
                            <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
                            <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                            <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
                            <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
                            <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                            <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                            <h2>Governing Law</h2>
                            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
                            <h2>Disputes Resolution</h2>
                            <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                            <h2>For European Union (EU) Users</h2>
                            <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which You are resident.</p>
                            <h2>United States Legal Compliance</h2>
                            <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
                            <h2>Severability and Waiver</h2>
                            <h3>Severability</h3>
                            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                            <h3>Waiver</h3>
                            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
                            <h2>Translation Interpretation</h2>
                            <p>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
                            <h2>Changes to These Terms and Conditions</h2>
                            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
                            <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                            <h2>Contact Us</h2>
                            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                            <ul>
                                <li>By visiting this page on our website: <a href="https://theindianstudent.com/#/terms" rel="external nofollow noopener" target="_blank">https://theindianstudent.com/#/terms</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>    
    <!-- TnC Modal End -->
</template>
<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import Loader from '@/helpers/Loader.vue';
import SignupValidations from '@/services/SignupValidations';
//import vueConfig from 'vue.config';

let error_msg = [];
let country_of_study = '';
let residence_country = '';
let city_of_study = '';
let institute_name = '';
let graduation_year = '';
let degree_type = '';
let course_type = '';
let current_status = '';
let education_mode = '';
let degree_status =  '';
let filtered_universityList = [];
let cityList = [];
let linkedin_profile_url = 'https://www.linkedin.com/in/';
let api_url = (process.env.VUE_APP_ENV == 'local') ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_PROD_API;

const yearList = [
    {
        id: 1,
        text: '2013'
    },
    {
        id: 2,
        text: '2014'
    },
    {
        id: 3,
        text: '2015'
    },
    {
        id: 4,
        text: '2016'
    },
    {
        id: 5,
        text: '2017'
    },
    {
        id: 6,
        text: '2018'
    },
    {
        id: 7,
        text: '2019'
    },
    {
        id: 8,
        text: '2020'
    },
    {
        id: 9,
        text: '2021'
    },
    {
        id: 10,
        text: '2022'
    },
    {
        id: 11,
        text: '2023'
    },
    {
        id: 12,
        text: '2024'
    },
    {
        id: 13,
        text: '2025'
    },
    {
        id: 14,
        text: '2026'
    },
    {
        id: 15,
        text: '2027'
    },
    {
        id: 16,
        text: '2028'
    },
    {
        id: 17,
        text: '2029'
    }
];

$(document).ready(function() {

    $(document).on('click', '#nexttab', function(e) {
        e.preventDefault();
        let user = {
            full_name: $('#full_name').val(),
            personal_email: $('#personal_email').val(),
            gender: $("input[name = 'gender']:checked").val(),
            dob: $('#dob').val()
        }
        console.log(user);
        let validations = new SignupValidations(user);
        error_msg = validations.checkValidations();

        console.log('error_msg...',error_msg);
        //console.log('error_msg length...',Object.keys(error_msg).length);
        const error_length = (error_msg == undefined) ? 0 : Object.keys(error_msg).length;
        console.log('error_length....',error_length);

        if(error_length > 0) {
            
            $.each(Object.keys(error_msg), function(i, v) {
                $('.'+v+'_msg').addClass('invalid-msg').text(error_msg[v]);
                $(this).addClass('invalid-input').removeClass('valid-input');
            });

            return false;
        }
        else {
            e.preventDefault();
            $('#academic-tab').addClass('active');
            $('#nav-academic').addClass('show active');

            $('#personal-tab').removeClass('active');
            $('#nav-personal').removeClass('show active');
        }        
    });

    $(document).on('click', '#academic-tab', function(e) {
        e.preventDefault();

        let user = {
            degree_type: $('#degree_type').val(),
        }
        console.log(user);
        let validations = new SignupValidations(user);
        error_msg = validations.checkAcademics();
        console.log('error_msg....', error_msg);

        if(Object.keys(error_msg).length > 0) {

            $.each(Object.keys(error_msg), function(i, v) {
                $('.'+v+'_msg').addClass('invalid-msg').text(error_msg[v]);
                $(this).addClass('invalid-input').removeClass('valid-input');
            });

            return false;
        }
        else {
            $(this).addClass('active');
            $('#nav-academic').addClass('show active');
            $('#personal-tab').removeClass('active');
            $('#nav-personal').removeClass('show active');
        }
    });

    $(document).on('click', '#personal-tab', function(e) {
        e.preventDefault();
        $(this).addClass('active');
        $('#nav-personal').addClass('show active');
        $('#academic-tab').removeClass('active');
        $('#nav-academic').removeClass('show active');
    });

    $(document).on('click', '.otp-close', function(e) {
        e.preventDefault();
        $('#OTPModal').modal('toggle');
    });

    $(document).on('click', '.tnc-close', function(e) {
        e.preventDefault();
        $('#TNCModal').modal('toggle');
    });

    $(document).on('select2:select', '#country_of_study', function(e) {
        console.log('Selecting');
        e.preventDefault();
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        country_of_study = text;
 
        let new_filtered_universityList = [];
 
        new_filtered_universityList = filtered_universityList.filter(temp => temp.country_code.includes(val));
 
        $('#institute_name').empty();
       
        $('#institute_name').prepend('<option></option>').select2({
            placeholder: 'Name the university of study',
            data: new_filtered_universityList
        });
        $('#institute_name').trigger('change');  
    });

    $(document).on('select2:select', '#institute_name', function(e) {
    //$('#institute_name').on('select2:select', function(e) {
        e.preventDefault();
        let data = e.params.data;
        let cc = data['country_code'];
        console.log('data', data);
        console.log('cc', cc);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        institute_name = text;
        console.log(`institute text: ${text}`);
        console.log(`institute val: ${val}`);
        console.log(`institute cc: ${cc}`);
        console.log(`citylist before: ${cityList}`);
        const filtered_cityList = cityList.filter(temp => temp.id.includes(cc));
        console.log(`citylist after: ${filtered_cityList}`);
        const unique_filtered_cityList = filtered_cityList.filter((obj, index) => {
            return index === filtered_cityList.findIndex(o => obj.text === o.text);
        });
        console.log(unique_filtered_cityList);
        //filtered_cityList.filter((item, index) => filtered_cityList.indexOf(item.text) === index);
        //filtered_cityList.forEach(ele=>{
            //console.log(ele);
        //});
 
        $('#city_of_study').empty();
       
        $('#city_of_study').prepend('<option></option>').select2({
            placeholder: 'Locate the city of your study',
            data: unique_filtered_cityList
        });
        $('#city_of_study').trigger('change');  
    });
   
    $(document).on('change', '#city_of_study', function(e) {
        e.preventDefault();
        //alert(1);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        city_of_study = text;
        console.log(city_of_study);
    });

    $(document).on('change', '#current_status', function(e) {
        e.preventDefault();
        //alert(1);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        current_status = text;
        console.log(current_status);
    });

    $(document).on('change', '#graduation_year', function(e) {
        e.preventDefault();
        //alert(3);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        graduation_year = text;
        console.log(graduation_year);
    });

    $(document).on('change', '#degree_type', function(e) {
        e.preventDefault();
        //alert(4);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        degree_type = text;
        console.log(degree_type);
    });

    $(document).on('change', '#course_type', function(e) {
        e.preventDefault();        
        //alert(5);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        course_type = text;
        console.log(course_type);
    });

    $(document).on('input', '#full_name', function(e) {

        e.preventDefault();
        var fullname = $(this).val();
        var validname = /^[a-zA-Z ]*$/;

        if(fullname.length == 0) {
            //this.error.name = 'Name is required';
            $('.full_name_msg').addClass('invalid-msg').text("Name is required");
            $(this).addClass('invalid-input').removeClass('valid-input');
        }  
        else if (!validname.test(fullname)) {
            $('.full_name_msg').addClass('invalid-msg').text('only characters & Whitespace are allowed');
            $(this).addClass('invalid-input').removeClass('valid-input');
        }
        else {
            $('.full_name_msg').empty();
            $(this).addClass('valid-input').removeClass('invalid-input');
        }
    });

    $(document).on('blur', '#full_name', function(e) {

        e.preventDefault();
        var fullname = $(this).val();
        if(fullname.length == 0) {
            $('.full_name_msg').addClass('invalid-msg').text("Name is required");
            $(this).addClass('invalid-input').removeClass('valid-input');
            error_msg['name'] = 'Name is required';
        }  
        else {
            $('.full_name_msg').empty();
            $(this).addClass('valid-input').removeClass('invalid-input');
        }
    });

    $(document).on('input', '#personal_email', function(e) {

        e.preventDefault();
        var emailAddress = $(this).val();
        //console.log('gotcha email:...', emailAddress);
        //console.log('api_url:...', api_url);
        //return false;
        var validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (emailAddress.length == 0) {
            $('.personal_email_msg').addClass('invalid-msg').text('Email is required');
            $(this).addClass('invalid-input').removeClass('valid-input');
        }
        else if (!validEmail.test(emailAddress)) {
            $('.personal_email_msg').addClass('invalid-msg').text('Invalid Email Address');
            $(this).addClass('invalid-input').removeClass('valid-input');
        }
        else {
            $('.personal_email_msg').empty();
            $(this).addClass('valid-input').removeClass('invalid-input');
            
            verifyEmail(emailAddress).then(response => {
                if(response.status == true) {
                    error_msg['email'] = response.message;
                    $('.personal_email_msg').addClass('invalid-msg').text(response.message);
                    $(this).addClass('invalid-input').removeClass('valid-input');
                }
            });
        }
    });

    $(document).on('input', '#dob', function(e) {

        e.preventDefault();
        var dob = $(this).val();
        if(dob.length == 0) {
            $('.dob_msg').addClass('invalid-msg').text("Birth Date is required");
            $(this).addClass('invalid-input').removeClass('valid-input');
            error_msg['dob'] = 'Birth Date is required';
        } 
        else if(dob.length > 0) {
            var bdate = new Date(dob);
            var today = new Date();
            var age = Math.floor((today-bdate)/(365.25*24*60*60*1000));
            console.log('age is...', age);
            var eligilible = (age > 18) ? true : false;
            if(!eligilible) {
                $('.dob_msg').addClass('invalid-msg').text('Please note that user access to this platform is strictly reserved for individuals who are 18 years of age or older.');
                $(this).addClass('invalid-input').removeClass('valid-input');
                error_msg['dob'] = 'Please note that user access to this platform is strictly reserved for individuals who are 18 years of age or older.';
            }
            else {
                $('.dob_msg').empty();
                $(this).addClass('valid-input').removeClass('invalid-input');
            }
        } 
        else {
            $('.dob_msg').empty();
            $(this).addClass('valid-input').removeClass('invalid-input');
        }
    }); 
    
    $(document).on('change', 'input[type="radio"][name="gender"]', function(e) {
        e.preventDefault();
        var gender =  $(this).val();//$("input[name='gender']:checked").val(); //$(this+' :checked').val(); 
        console.log('gender...', gender);
        if(gender) {
            $('.gender_msg').empty();
            $(this).addClass('valid-input').removeClass('invalid-input');     
        }
    });

    $(document).on('change', 'input[type="radio"][name="indian_national"]', function(e){
        e.preventDefault();
        var radioValue = $("input[name='indian_national']:checked").val();
        if(radioValue == 'N'){

            let elems = $('#nav-personal').find(':input');
            console.log(elems);

            for(let i = 2; i < elems.length; i++) {
                elems[i].disabled = true;
                elems[i].tabindex = -1;
            }

            $('#nexttab').addClass('disable-click');
            $('#academic-tab').prop('disabled', true);

            let msg = 'We kindly inform you that this platform is solely intended for Indian Nationals. If you identify as one, please choose accordingly.';
            toast.info( msg,{ autoClose: 5000 });   
        }
        if(radioValue == 'Y') {

            let elems = $('#nav-personal').find(':input');
            console.log(elems);

            for(let i = 2; i < elems.length; i++) {
                elems[i].disabled = false;
            }

            $('#nexttab').removeClass('disable-click');
            $('#academic-tab').prop('disabled', false);
        }
    });

    $(document).on('change', 'input[type="radio"][name="degree_status"]', function(e) {
        e.preventDefault();
        degree_status =  $("input[name='degree_status']:checked").val();
        education_mode = $("input[name='education_mode']:checked").val();

        console.log('degree status....',degree_status);
        console.log('education mode....',education_mode);

        if(education_mode == 'offline' && degree_status == 'P') {
            $('#residence_country').select2().next().hide();
            //residence_country = country_of_study;
        }
        else {
            $('#residence_country').prepend('<option></option>').select2({placeholder:'Select your country of residence'}).trigger('change');
            //$('#residence_country').select2().next().show();
        }
        //console.log('degree_status...', degree_status);
        const newYearList = [];
        //console.log('new yearlist...', newYearList);
        let currentYear = new Date().getFullYear();
        let cntr = 5;
        $('#graduation_year').empty();
        $('#current_status').empty();
        //manage yearlist based on degree status
        if(degree_status == 'C') {

            if(typeof education_mode === 'undefined') {
                $('#residence_country').select2().next().hide();
            }
            else if(typeof education_mode !== 'undefined' && education_mode != 'offline') {
                $('#residence_country').prepend('<option></option>').select2({placeholder:'Select your country of residence'}).trigger('change');
            }

            for(let i=0; i<=cntr; i++) {
                newYearList.push(currentYear-i);
            }

            $('#current_status').prepend('<option></option>').select2({
                placeholder: 'What is your current status?',
                data: [    
                    {id:0, text:'Working'},
                    {id:1, text:'Studying Further'}
                ],
                tags: true
            });
            $('#current_status').trigger('change');
        }
        else {

            if(typeof education_mode === 'undefined') {
                $('#residence_country').select2().next().hide();
            }
            else if(education_mode == 'offline') {
                $('#residence_country').select2().next().hide();
            }
            else if(education_mode != 'offline'){
                ('#residence_country').prepend('<option></option>').select2({placeholder:'Select your country of residence'}).trigger('change');
            }

            for(let i=0; i<=cntr; i++) {
                newYearList.push(currentYear+i);
            }

            $("#current_status").prepend('<option></option>').select2({
                placeholder: 'What is your current status?',
                data: [{
                    id: 0,
                    text: 'Student'
                }],
                tags: true
            });
            $('#current_status').trigger('change');
            
        }
        //console.log('new yearlist...', newYearList);
        $('#graduation_year').prepend('<option></option>').select2({
            placeholder: (degree_status == 'C') ? 'When did you completed your graduation?' : 'When will you complete your graduation?',
            data: newYearList
        });
        $('#graduation_year').trigger('change');
        //$('#graduation_year').select2().next().show();

        //$('#current_status').trigger('change');
        //$('#current_status').select2().next().show();
    }).trigger('change');    

    /*
    $("#country_of_study").change(function () {
        //alert(1);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        country_of_study = text;
        console.log(country_of_study);
    });

    $("#city_of_study").change(function () {
        //alert(1);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        city_of_study = text;
        console.log(city_of_study);
    });

    $("#institute_name").change(function () {
        //alert(2);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        institute_name = text;
        console.log(institute_name);
    });    
    */

    $(document).on('change', '#residence_country', function(e) {
    //$("#residence_country").change(function () {
        //alert(1);
        const val = $(this).find("option:selected").val();
        const text = $(this).find("option:selected").text();
        this.selected = val;
        residence_country = text;
        console.log(residence_country);
    });

    $(document).on('change', 'input[type="radio"][name="education_mode"]', function(e) {
        e.preventDefault();
        education_mode = $("input[name='education_mode']:checked").val();
        degree_status =  $("input[name='degree_status']:checked").val();

        console.log('degree status....',degree_status);
        console.log('education mode....',education_mode);

        if(education_mode == 'offline' && degree_status == 'P') {
            $('#residence_country').select2().next().hide();
            //residence_country = country_of_study;
        }
        else {
            //$('#residence_country').select2().next().show();
            $('#residence_country').prepend('<option></option>').select2({placeholder:'Select your country of residence'}).trigger('change');
        }

        //console.log('residence country....',residence_country);
    });

    $(document).on('click', '.tnc', function() {
        $('#TNCModal').modal('show');
    });

});

const verifyEmail = async (email) => {
    //console.log('api_url: ', api_url);debugger;
    let payload = { email: email };
    //let response = await axios.post('https://theindianstudent.com:8000/api/checkfield', payload);
    let response = await axios.post(`${api_url}/api/checkfield`, payload);
    return response.data;
}

$(function() {

    /*
    $('#nexttab').click(function(e) {
        //$('#graduation_year').select2().next().hide();
        //$('#current_status').select2().next().hide();

        let user = {
            full_name: $('#full_name').val(),
            personal_email: $('#personal_email').val(),
            gender: $("input[name = 'gender']:checked").val(),
            dob: $('#dob').val()
        }
        console.log(user);
        let validations = new SignupValidations(user);
        error_msg = validations.checkValidations();

        //console.log('error_msg...',error_msg);
        //console.log('error_msg...',Object.keys(error_msg).length);

        if(Object.keys(error_msg).length > 0) {
            
            $.each(Object.keys(error_msg), function(i, v) {
                $('.'+v+'_msg').addClass('invalid-msg').text(error_msg[v]);
                $(this).addClass('invalid-input').removeClass('valid-input');
            });

            return false;
        }
        else {
            e.preventDefault();
            $('#academic-tab').addClass('active');
            $('#nav-academic').addClass('show active');

            $('#personal-tab').removeClass('active');
            $('#nav-personal').removeClass('show active');
        }
    });

    $('#academic-tab').click(function(e) {
        e.preventDefault();
        //$('#graduation_year').select2().next().hide();
        //$('#current_status').select2().next().hide();

        let user = {
            full_name: $('#full_name').val(),
            personal_email: $('#personal_email').val(),
            gender: $("input[name = 'gender']:checked").val(),
            dob: $('#dob').val()
        }
        console.log(user);
        let validations = new SignupValidations(user);
        error_msg = validations.checkValidations();
        
        if(Object.keys(error_msg).length > 0) {

            $.each(Object.keys(error_msg), function(i, v) {
                $('.'+v+'_msg').addClass('invalid-msg').text(error_msg[v]);
                $(this).addClass('invalid-input').removeClass('valid-input');
            });

            return false;
        }
        else {
            $(this).addClass('active');
            $('#nav-academic').addClass('show active');
            $('#personal-tab').removeClass('active');
            $('#nav-personal').removeClass('show active');
        }
    });

    $('#personal-tab').click(function(e) {
        e.preventDefault();
        $(this).addClass('active');
        $('#nav-personal').addClass('show active');
        $('#academic-tab').removeClass('active');
        $('#nav-academic').removeClass('show active');
    });

    $('.otp-close').click(function(e) {
        $('#OTPModal').modal('toggle');
    });

    $('.tnc-close').click(function(e) {
        $('#TNCModal').modal('toggle');
    });
    */

});

export default {
    name: 'Signup',
    components: {
        Loader
    },
    data () {
        return {
            result: {},
            errors: [],
            loading: false,
            user: {
                indian_national: '',
                full_name: '',
                personal_email: '',
                phone: '',
                gender: '',
                dob: '',
                linkedin_profile: '',
                tnc_acceptance: '',
                residence_country: '',
                country_of_study: '',
                city_of_study: '',
                degree_status: '',
                graduation_year: '',
                current_status: '',
                degree_type: '',
                course_type: '',
                institute_name: '',
                insitute_campus: '',
                academic_email: '',
                education_mode: ''
            },
            user_creds: {
                personal_email: '',
                otp_password: ''
            }
        }
    },
    mounted () {
        this.focusInput();
        this.getStudyCountry();
        this.$nextTick(()=>{
            console.log('document loaded...');
            this.$refs.name.focus();
        });
    },
    methods: {
        focusInput() {
            console.log('focusing....');
            this.$nextTick(() => {
                // This callback will only be called after the
                // DOM has been updated
                this.$refs.name.focus();
            });
        },
        async validateField(field) {

            console.log('field is ', field);
            /*
            if (field == 'email' && !this.user.personal_email) {
                this.errors.email = ''
                return;
            }

            if (field == 'phone' && !this.user.phone) {
                this.errors.phone = ''
                return;
            }
            */
            if(field == 'email' || field == 'phone') {
                let payload = (field == 'email') ? { email: this.user.personal_email } : { phone: this.user.phone } ;
                //let response = await axios.post('https://theindianstudent.com:8000/api/checkfield', payload);
                let response = await axios.post(`${api_url}/api/checkfield`, payload);
                console.log('emailcheck output..',response);
                //this.errors.email = (!response.status) ? 'Email already exist' : 'Hurray! Good to Go';
                this.errors.email = response.data.message;
                error_msg['email'] = response.data.message;
                toast.info( response.data.message,{ autoClose: 5000 });
                /*
                if(field == 'email' && response.data.status == true) {
                    this.errors.email = response.data.message;
                    error_msg['email'] = response.data.message;
                }
                if(field == 'phone' && response.data.status == true) {
                    this.errors.phone= response.data.message;
                    error_msg['phone'] = response.data.message;
                }
                */
            }
        },
        async getStudyCountry() {
            const countrylist = [];
            //const cityList = [];
            const universityList = [];
            const degreeList = [
                {
                    id: 1,
                    text: 'Bachelors'
                },
                {
                    id: 2,
                    text: 'Masters'
                },
                {
                    id: 3,
                    text: 'PHD'
                },
                {
                    id: 4,
                    text: 'Short Course'
                }
            ];

            const courseList = [
                {
                    id: 1,
                    text: 'Economics'
                },
                {
                    id: 2,
                    text: 'Political Science and International Affairs'
                },
                {
                    id: 3,
                    text: 'Business Administration'
                },
                {
                    id: 4,
                    text: 'Computer and Information Science'
                },
                {
                    id: 5,
                    text: 'Industrial Engineering and Systems Management'
                },
                {
                    id: 6,
                    text: 'Law'
                },
                {
                    id: 7,
                    text: 'Public Health Program'
                },
                {
                    id: 8,
                    text: 'Master of Business Administration in Tourism and International Business'
                },
                {
                    id: 9,
                    text: 'Human Resource Management'
                },
                {
                    id: 10,
                    text: 'International Business'
                },
                {
                    id: 11,
                    text: 'Management for Engineers'
                },
                {
                    id: 12,
                    text: 'Marketing'
                },
                {
                    id: 13,
                    text: 'Master of Business Administration'
                },
                {
                    id: 14,
                    text: 'Professional Accounting'
                },
                {
                    id: 15,
                    text: 'Project Management'
                },
                {
                    id: 16,
                    text: 'Informatics'
                },
                {
                    id: 17,
                    text: 'Information Systems'
                },
                {
                    id: 18,
                    text: 'Information Technology'
                },
                {
                    id: 19,
                    text: 'Accident Investigation'
                },
                {
                    id: 20,
                    text: 'Accident Phenomenology'
                },
                {
                    id: 21,
                    text: 'Advanced Safety Science Practice'
                },
                {
                    id: 22,
                    text: 'Health Science'
                },
                {
                    id: 23,
                    text: 'Occupational Health and Safety'
                },
                {
                    id: 24,
                    text: 'Podiatry Practice'
                },
                {
                    id: 25,
                    text: 'Applied Science'
                },
                {
                    id: 26,
                    text: 'Business'
                },
                {
                    id: 27,
                    text: 'Education'
                }
            ];

            //const url = 'https://us-central1-auxilo-cx-agent-363010.cloudfunctions.net/auximaster';
            this.loading = true;
            await axios.get(`${api_url}/api/universities`).then(response=>{
                
                $.each(response.data.data, function(index, value) {
                    universityList.push({
                        "id": value.id,
                        "text": (value.name).trim(),
                        "country_code": value.country_code,
                        "country_name": (value.name).trim()
                    });

                    countrylist.push({
                        "id":  (value.country_code).trim(),//(value.country_name).trim(),//value.id,
                        "text": (value.country_name).trim(),
                        "country_name": (value.country_code).trim()
                    });

                    if(value.city_name !== null) {
                        cityList.push({
                            "id": (value.country_code).trim(),
                            "text": value.city_name
                        });
                    }
                });

                console.log("citylist...",cityList);
                //remove duplicates
                const universityids = universityList.map(({ country_name }) => country_name);
                filtered_universityList = universityList.filter(({ country_name }, index) =>
                !universityids.includes(country_name, index + 1));
        
                //sort alphabetics
                filtered_universityList.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));

                //remove duplicates
                const countryids = countrylist.map(({ country_name }) => country_name);
                const filtered_countrylist = countrylist.filter(({ country_name }, index) =>
                !countryids.includes(country_name, index + 1));
        
                //sort alphabetics
                filtered_countrylist.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));

                //console.log('countrylist: ',filtered_countrylist);
                //console.log('universityList: ',filtered_universityList);

                $('#country_of_study').prepend('<option></option>').select2({
                placeholder: 'Select your country of study',
                data: filtered_countrylist,
                });
                $('#country_of_study').trigger('change');

                $('#residence_country').prepend('<option></option>').select2({
                placeholder: 'Select your country of residence',
                data: filtered_countrylist,
                });
                $('#residence_country').trigger('change');

                $('#residence_country').select2().next().hide();

                $('#institute_name').prepend('<option></option>').select2({
                placeholder: 'Name your university of study',
                data: [],
                });
                $('#institute_name').trigger('change');

                $('#graduation_year').prepend('<option></option>').select2({
                    placeholder: 'Select your year of graduation',
                    data: []
                });
                $('#graduation_year').trigger('change');

                $('#degree_type').prepend('<option></option>').select2({
                    placeholder: 'Select your Academic Level',
                    data: degreeList
                });
                $('#degree_type').trigger('change');
                
                $('#course_type').prepend('<option></option>').select2({
                    placeholder: 'Select your subject of specialization',
                    data: courseList
                });
                $('#course_type').trigger('change');

                $("#current_status").prepend('<option></option>').select2({
                    placeholder: 'What is your current status?',
                    data: [],
                    tags: true
                });
                $("#current_status").trigger('change');

                $('#city_of_study').prepend('<option></option>').select2({
                placeholder: 'Locate the city of your study',
                data: [],
                });
                $('#city_of_study').trigger('change');

                this.loading = false;
            });
        },
        async saveData () {
            //this.user.country_of_study = $("#country_of_study :selected").text();
            //this.user.institute_name = $("#institute_name :selected").text();
            //this.user.indian_national = $("input[name = 'indian_national']:checked").val();
            //$('#country_of_study').trigger('change');
            //$('#graduation_year').trigger('change');
            //$('#degree_type').trigger('change');
            //$('#course_type').trigger('change');
            //$('#institute_name').trigger('change');
            //this.user.country_of_study = $('#country_of_study').find(':selected').text();
            //this.user.institute_name = $('#institute_name').find(':selected').text();
            //this.user.indian_national = $("input[name = 'indian_national']:checked").val();
            //this.user.graduation_year = $('#graduation_year').find(':selected').text();
            //this.user.degree_type = $('#degree_type').find(':selected').text();
            //this.user.course_type = $('#course_type').find(':selected').text();

            this.user.linkedin_profile = linkedin_profile_url+this.user.linkedin_profile;
            this.user.country_of_study = country_of_study;
            this.user.residence_country = (education_mode == 'offline' && degree_status == 'P') ? country_of_study : residence_country;
            this.user.city_of_study = city_of_study;
            this.user.institute_name = institute_name;
            this.user.graduation_year = graduation_year;
            this.user.degree_type = degree_type;
            this.user.course_type = course_type;
            this.user.indian_national = $("input[name = 'indian_national']:checked").val();
            this.user.education_mode = $("input[name = 'education_mode']:checked").val();
            this.user.gender = $("input[name = 'gender']:checked").val();
            this.user.current_status = current_status;
            this.user.tnc_acceptance = (this.user.tnc_acceptance == true) ? 'Y' : 'N';
            
            console.log('user data...',this.user); debugger;
            //let validations = new SignupValidations(this.user.personal_email, this.user.phone);
            let validations = new SignupValidations(this.user);
            error_msg = validations.checkAcademics();
            console.log('academic error_msg...',error_msg);
            const error_length = (error_msg == undefined) ? 0 : Object.keys(error_msg).length;
            console.log('error_length....',error_length);

            if(error_length > 0) {

                $.each(Object.keys(error_msg), function(i, v) {
                    $('.'+v+'_msg').addClass('invalid-msg').text(error_msg[v]);
                    $(this).addClass('invalid-input').removeClass('valid-input');
                });

                //$('#nav-academic').removeClass('show active');
                //$('#nav-personal').addClass('show active');
                //$('#academic-tab').removeClass('active');
                //$('#personal-tab').addClass('active');
                return false;
            }
            else {
                try {
                    if(this.user.indian_national == 'N') {
                        let msg = 'We kindly inform you that this platform is solely intended for Indian Nationals. If you identify as one, please choose accordingly.';
                        toast.info( msg,{ autoClose: 5000 }); 
                        return false;
                    }

                    if(this.user.tnc_acceptance == 'N') {
                        let msg = 'Kindly accept the terms of conditions by selecting it.';
                        toast.info( msg,{ autoClose: 5000 }); 
                        return false;
                    }
                    
                    this.loading = true;
                    //let response = await axios.post('https://theindianstudent.com:8000/api/signup', this.user);
                    let response = await axios.post(`${api_url}/api/signup`, this.user);
                    console.log('response in signup...',response);
                    if(response.status == 200 && response.data.success) {
                        //this.$router.push({ name: 'Login' });
                        this.loading = false;
                        $('#OTPModal').modal('show');
                    }
                    if(response.status == 200 && !response.data.success) {
                        this.errors = response.data.errors;
                        $('#nav-academic').removeClass('show active');
                        $('#nav-personal').addClass('show active');
                        $('#academic-tab').removeClass('active');
                        $('#personal-tab').addClass('active');
                        console.log('errors in signup...',this.errors);
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
        },
        async login() {
            console.log('user data...',this.user);
            this.user_creds.personal_email = this.user.personal_email;
            console.log('user creds...',this.user_creds);
            this.loading = true;
            try {
                //await axios.post('https://theindianstudent.com:8000/api/login', this.user_creds).then(({data})=>{
                await axios.post(`${api_url}/api/login`, this.user_creds).then(({data})=>{
                    console.log('login api response.',data);
                    //localStorage.setItem('usertoken', data.data.token);
                    sessionStorage.setItem('usertoken', data.data.token);
                    sessionStorage.setItem('user_id', data.data.user.id);
                    sessionStorage.setItem('user_status', data.data.user_academics.current_status);
                    sessionStorage.setItem('user_university', data.data.user_academics.institute_name);
                    sessionStorage.setItem('user_course', data.data.user_academics.course_type);
                    sessionStorage.setItem('user_studycountry', data.data.user_academics.country_of_study);
                    
                    if(data.success) {
                        let query_obj = { 
                            first_survey: true,
                            form_id: 'xEb4fKfo',
                            user_id: data.data.user.id,
                            usr_nme: data.data.user.full_name,
                            usr_email: data.data.user.personal_email,
                            course: data.data.user_academics.course_type,
                            uni_name: data.data.user_academics.institute_name,
                            camp: data.data.user_academics.institute_campus,
                            crse_stat: data.data.user_academics.degree_status,
                            crnt_stat: data.data.user_academics.current_status,
                            crse_dt: data.data.user_academics.education_mode,
                            uni_cntry: data.data.user_academics.country_of_study,
                            camp_cntry: data.data.user_academics.country_of_study,
                            camp_cty: data.data.user_academics.city_of_study,
                            crs_yr_cmpltd: data.data.user_academics.graduation_year,
                            dgre: data.data.user_academics.degree_type,
                            survey_id: 1
                        };

                        sessionStorage.setItem('username', data.data.user.full_name);
                        let randomNumber = (Math.random() + 1).toString(36).substring(7);
                        let nv_uid = `${data.data.user.full_name}_${randomNumber}`;
                        nv('user', `${nv_uid}`,{name: `${data.data.user.full_name}`});

                        this.$router.push({ 
                            path: 'survey', 
                            query: query_obj 
                        });
                    }

                    /*
                    if(data.success) {
                        axios.get('https://theindianstudent.com:8000/api/user', {
                            headers: {
                                Authorization: 'Bearer '+data.data.token
                            }
                        }).then((response)=>{
                            console.log('user details..',response.data);
                            let form_id = 'xEb4fKfo';
                            let user_id = response.data.id;
                            let username = response.data.full_name;
                            let survey_id = 1;
                            //localStorage.setItem('username', username);
                            sessionStorage.setItem('username', username);
                            //this.opentypeform(form_id, username, user_id, survey_id);
                            this.$router.push({ 
                                path: 'survey', 
                                query: { 
                                    first_survey: true,
                                    form_id: 'xEb4fKfo',
                                    user_id: response.data.id,
                                    user_name: username,
                                    survey_id: survey_id
                                } 
                            });
                        });
                    }
                    */
                });
            }
            catch(errorotp) {
                if (errorotp.response && errorotp.response.status === 401) {
                    let messageOTP = 'Sorry, the OTP entered is incorrect. Please re-confirm and try again';
                    $('.errorOTP').removeClass('d-none');
                    $('.errorOTP').text(messageOTP);
                }
            }
        }
        /*,
        opentypeform(survey_formid, user_name, user_id, survey_id) {
            typeformEmbed.makePopup("https://theindianstudent.typeform.com/to/"+survey_formid+"#name="+user_name, {
                //mode: "drawer_right",
                open: "load",
                autoClose: 3, //close form # seconds after submission
                hideScrollbars: true,
                onSubmit: function() {
                    console.log("Typeform successfully submitted");
                    console.log("user_id and survey_id",user_id, survey_id);
                    let usersurvey = {
                        user_id: user_id,
                        survey_id: survey_id,
                        status: ''
                    };
                    axios.post('https://theindianstudent.com:8000/api/updatesurveystatus', usersurvey).then(({data})=>{
                        console.log(data);
                        if(data.success) {
                            //usersurvey.status = 'complete';
                            //this.userSurveyObj = usersurvey;
                            //location.reload();
                            //this.$router.push('/survey');
                            window.location.href = '/survey?first_survey=false';
                        }
                    });
                },
                onReady: function() {
                    console.log("Typeform is ready");
                },
                onClose: function() {
                    console.log("Typeform is closed");
                }
            });
        }
        */
    }
}
</script>
<style scoped>
.disable-click{
    pointer-events:none;
}
.valid-input{
    border:1px solid green !important;
}
.invalid-input{
    border:1px solid red !important;
}
.invalid-msg{
    color: red;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/css/font-awesome.min.css');
</style>