<template>
    <div v-if="loading">
      <Loader/>
    </div>
    <div v-else>
        <section class="position-relative cs-section">
            <img src="@/assets/images/coming-soon/pennant-2.gif" alt="" class="bann bann1">
            <img src="@/assets/images/coming-soon/pennant-2.gif" alt="" class="bann bann2">

            <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="text-center pt-5">
                <img src="@/assets/images/Indian-Student-Logo.svg" alt="" width="250" class="logo">
                </div>

                <div class="container-fluid mt-5 mt-lg-0">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-sm-6 text-center">
                    <div class="position-relative d-inline-block">
                        <img src="@/assets/images/coming-soon/startup-2.gif" alt="" class="img-fluid startup">
                        <!-- Stars -->
                        <img src="@/assets/images/coming-soon/favourite-2.gif" alt="" class="stars stars1">
                        <img src="@/assets/images/coming-soon/favourite-2.gif" alt="" class="stars stars2">
                        <img src="@/assets/images/coming-soon/favourite-2.gif" alt="" class="stars stars3">
                        <img src="@/assets/images/coming-soon/favourite-2.gif" alt="" class="stars stars4">
                        <img src="@/assets/images/coming-soon/favourite-2.gif" alt="" class="stars stars5">
                    </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 text-center">
                    <h2 class="fw-bold text-uppercase mb-0 blue-text float2">Coming</h2>
                    <img src="@/assets/images/coming-soon/soon-2.gif" alt="" class="img-fluid soon-2 mt-n1">
                    <h4 class="mt-2">Join us at the cusp<br>
                        of a new dawn.</h4>

                    </div>
                    <div class="col-lg-4 text-center mt-3 mt-sm-5 mt-lg-0">
                    <img src="@/assets/images/coming-soon/study-abroad.svg" alt="" class="cs-abroad-vector">
                    </div>
                </div>
                </div>
            </div>
            </div>
            <img src="@/assets/images/coming-soon/stage.svg" alt="" class="stage">
            <img src="@/assets/images/coming-soon/circle-dots.svg" alt="" class="circle-dots">
            <img src="@/assets/images/coming-soon/circle-dots.svg" alt="" class="circle-dots-2 d-lg-flex d-none">
        </section>        
        <!--
        <section class="coming-soon">
            <div class="container h-100">
                <div class="row justify-content-center align-items-center h-100">
                <div class="col-12 col-lg-9 text-center">
                    <div><img src="@/assets/images/coming-soon/Logo.png" alt="" class="cs-logo"></div>
                    <div class="py-5"><img src="@/assets/images/coming-soon/Coming-Soon-Text.png" alt="" class="cs-text"></div>
                    <h4 class="text-white">Join us at the cusp of a new dawn. With TIS, we're weaving dreams for the Indian students venturing abroad, casting strong ropes of guidance, resources, and networking opportunities. Amidst the vast skies of academic pursuits, we help lighting the path to informed choices. Become a part of this inspiring endeavour, connecting hearts, and uniting dreams at TIS.
                    </h4>
                </div>
                </div>
            </div>
        </section>
        -->
    </div>
</template>
<script>
import Loader from '@/helpers/Loader';
import store from '@/composables';

export default {
    name: 'Indian',
    setup () {
        const { loading, init } = store();
        init();
        return {
            loading 
        }
    },
    components: {
        Loader
    }
}
</script>