import Validations from "./Validations";

export default class LoginValidations {

    constructor(userObj) {
        //this.email = email;
        //this.password = password;
        this.userObj = userObj;
    }

    checkValidations() {
        
        let errors = [];

        //email validation
        //if(!Validations.checkEmail(this.email).status) {
        if(!Validations.checkEmail(this.userObj).status) {
            //errors['email'] = 'Invalid Email'
            errors['email'] = Validations.checkEmail(this.userObj).msg;
        }

        //password validation
        if(!Validations.minPassword(this.userObj, 5).status) {
            //errors['password'] = 'Password should be min 5 characters';
            errors['password'] = Validations.minPassword(this.userObj, 5).msg;
        }

        return errors;
    }
 
}