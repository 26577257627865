<template>
    <!-- Hero Banner begin-->
    <section class="inner-banner py-4">
        <div class="container text-center">
            <img src="@/assets/images/home/icons/Terms.svg" alt="Terms" class="img-fluid" width="66">
            <h1 class="fw-semibold fs-28 dark-purple mb-0 mt-1">Contest Rules and Regulations</h1>
        </div>
    </section>
    <!-- Hero Banner end -->
    <section class="sec-pad pt-0 p-75">
        <div class="container">
            <div class="row mt-3 mt-sm-5">
                <div class="col-12">
                    <h3 class="mb-4">The Indian Student Survey Sweepstakes Contest Rules and Regulations </h3>
                    <ul>
                        <li><b>Eligibility:</b> Open to legal Indian students, 18 years or older, enrolled in or have completed their education at an international university. Employees of The Indian Student, its affiliates, and immediate family members are not eligible. Void where prohibited by law.
                        </li>
                        <li><b>Agreement to Rules:</b> By participating, you agree to these Rules, warrant your eligibility, and accept the Sponsor’s decisions as final.
                        </li>
                        <li><b>Sweepstakes Period:</b> Begins at 12:01 AM IST on the 1st of each month and ends at 11:59 PM IST on the last day of the month.
                        </li>
                        <li><b>How to Enter:</b> Complete all 6 designated surveys within a month for one entry. Incomplete entries or entries using multiple identities may be disqualified.
                        </li>
                        <li><b>Winner Selection and Notification:</b> Winners randomly selected under Sponsor supervision will be notified via email within seven days post-selection. Ineligible winners or those failing to claim prizes within 14 days will forfeit their winnings.
                        </li>
                        <li><b>Prizes:</b> Vouchers valued between $10 to $100 each, distributed through XOXOXDAY. No cash or substitution allowed, except by Sponsor. Winners bear all related taxes and expenses.
                        </li>
                        <li><b>Rights Granted by You:</b> You grant the Sponsor rights to use your entry, name, and likeness for publicity purposes without further compensation, unless prohibited by law.
                        </li>
                        <li><b>Terms:</b> The Sponsor reserves the right to cancel, modify, or suspend the Sweepstakes due to fraud or technical failures, and to disqualify tampering entrants.
                        </li>
                        <li><b>General Conditions:</b> Your participation signifies full agreement with these rules and reliance on survey integrity. Attempts to manipulate results lead to disqualification.
                        </li>
                        <li><b>Limitation of Liability:</b> Participants release Sponsor from liabilities arising from prize use or Sweepstakes participation, including technical failures or unauthorized interventions.
                        </li>
                        <li><b>Disputes:</b> Disputes resolved individually in Maharashtra courts, waiving rights to class actions and claims beyond actual out-of-pocket expenses.
                        </li>
                        <li><b>Privacy Policy:</b> Entry information is subject to our Privacy Policy [<a href="#/privacypolicy">https://theindianstudent.com/#/privacypolicy</a>].
                        </li>
                        <li><b>Sponsor:</b> The Sweepstakes is sponsored by The Indian Student, Mumbai, Maharashtra, India. </li>
                    </ul>
                    <p class="mt-4"><b>Disclaimer:</b> This Sweepstakes is not sponsored, endorsed, or administered by social media platforms. Information is provided to The Indian Student, not to social media companies.</p>
                </div>
            </div>
        </div>
     </section>    
</template>
<script>
export default {
    name: 'Contestrules'
}
</script>
<style scoped>
.p-75 p,
li {
    opacity: .9;
    font-size: 16px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
li {
    color: #000E39;
    opacity: .9;
    margin-bottom: 15px;
}
h2 {
    font-size: 32px;
    line-height: 48px;
}
h3 {
    font-size: 24px;
    line-height: 36px;
}
</style>