import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";
import Indian from '@/views/home/Indian';
import Overseas from '@/views/home/Overseas';
import Login from '@/views/Login';
import Signup from '@/views/Signup';
import Survey from '@/views/Survey';
import Winner from '@/views/Winner';
import Terms from '@/views/Terms';
import Privacypolicy from '@/views/Privacypolicy';
import Disclaimer from '@/views/Disclaimer';
import Cookiepolicy from '@/views/Cookiepolicy';
import Contestrules from '@/views/Contestrules';
import Contactus from '@/views/Contactus.vue';
import Eula from "@/views/Eula.vue";
import PageNotFound from '@/views/PageNotFound';
import Profile from '@/views/Profile';
import Contest from "@/views/Contest.vue";
import Gallery from "@/views/Gallery.vue";

const routes =  [
  {
    path: '/',
    components: {
      indian: Indian,
      overseas: Overseas
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey,
    beforeEnter(to, from, next) {
      if (sessionStorage.getItem('usertoken')) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/winner',
    name: 'Winner',
    component: Winner
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
    // beforeEnter: (to, from, next) => {
    //   // Reload the page when the Terms and Conditions link is clicked
    //   window.location.reload();
    // }
  },
  {
    path: '/privacypolicy',
    name: 'Privacypolicy',
    component: Privacypolicy
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/cookiepolicy',
    name: 'Cookiepolicy',
    component: Cookiepolicy
  }, 
  {
    path: '/eula',
    name: 'Eula',
    component: Eula
  },     
  {
    path: '/contestrules',
    name: 'Contestrules',
    component: Contestrules
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: Contactus
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/contest',
    name: 'Contest',
    component: Contest
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },  
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: PageNotFound
  },
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes: routes,
  linkActiveClass: 'active'
});
  
export default router;