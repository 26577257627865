<template>
    <div v-if="loading">
        <Loader/>
    </div>
    <div v-else>
        <section>
            <!--img src="@/assets/images/connecting-indians/TIS_Logo.svg" alt="TIS Logo" class="TIS-inner-logo"-->
            <div class="container text-center text-lg-start">
                <div class="row justify-content-center">
                    <div class="col-lg-4">
                        <div class="cs-title-text mt-0 mt-lg-5">
                            We are<br>
                            <span class="orange-text">Together</span><br>
                            Across the
                            <span class="green-text">Borders</span>
                        </div>
                        <div class="mt-4 mt-lg-5 mb-5 mb-lg-0"><a href="#/signup" class="btn tis-big-btn oswald">Join Now</a></div>
                    </div>
                    <div class="col-lg-8 text-center">
                        <img src="@/assets/images/connecting-indians/CS-banner.png" alt="Masthead Banner" class="img-fluid minus-img ">
                    </div>
                </div>
            </div>
        </section>
        <section class="pad-100 position-relative">
            <img src="@/assets/images/connecting-indians/left-circle.svg" alt="circle" class="left-circle position-absolute">
            <img src="@/assets/images/connecting-indians/line-3.svg" alt="Lines" class="line-right position-absolute">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <h1 class="cs-h1-text text-center mb-4">THE <span class="blue-text">Indian Student</span> Community</h1>
                        <p class="text-center">A beacon of unity and support, bridging the geographical divide for 1.3 million Indian students pursuing their dreams abroad. Our mission? To gather the collective wisdom of this expansive diaspora under a one unified platform, nurturing connections, support, and a resilient sense of home.</p>
                        <div class="position-relative text-center mt-5">
                            <img src="@/assets/images/connecting-indians/Student-Community.png" alt="" class="img-fluid"> <!--Center image-->
                            <div class="card feat-card comm-card comm-card1 d-flex flex-row align-items-center text-start float">
                                <div class="me-3"><img src="@/assets/images/connecting/indian-students.svg" alt="" width="40"></div>
                                <div>
                                    <h5 class="my-0">1.3 million</h5>
                                    <p class="opacity-75 my-0">Indian Students</p>
                                </div>
                            </div>
                            <div class="card feat-card comm-card comm-card2 d-flex flex-row align-items-center text-start float">
                                <div class="me-3"><img src="@/assets/images/connecting/airplane.svg" alt="" width="40"></div>
                                <div>
                                    <p class="opacity-75 my-0">Pursuing their</p>
                                    <h5 class="my-0">Dreams Abroad</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pad-100 position-relative">
            <img src="@/assets/images/connecting-indians/line-3.svg" alt="Lines" class="line-left position-absolute">
            <div class="container text-center text-lg-start">
                <div class="row flex-column-reverse flex-lg-row">
                    <div class="col-lg-5">
                        <div class="position-relative">
                            <img src="@/assets/images/connecting-indians/1.8.svg" alt="" class="insp-circle position-absolute">
                            <img src="@/assets/images/connecting-indians/Dreams.svg" alt="" class="img-fluid float">
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <h2 class="cs-h1-text mb-0">Turning <span class="blue-text">Dreams</span> into Impact </h2>
                        <h3 class="cs-h1-text mb-4"><span class="orange-text">You're</span> More Than Just a <span class="green-text">Student</span></h3>
                        <p>As a part of this empowering global fraternity, you're not just studying abroad, you're making a difference. <br><br> You stand as a testament to ambition, perseverance, and the fulfillment of dreams, <b>inspiring the 1.8 million aspiring students back home.</b> </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="pad-100 pt-0 text-center position-relative">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-8 text-center"><img src="@/assets/images/connecting-indians/Mark.png" alt="Mark" class="img-fluid "></div>
                </div>
                <div class="mark-bg">
                    <div class="row justify-content-center pt-5 pt-lg-0 d-none d-lg-flex">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-3">
                            <div class="mark-text1 oswald text-uppercase"><span class="yellow-text">Join</span><br>the Community</div>
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-3">
                            <div class="mark-text1 oswald text-uppercase"><span class="yellow-text">Influence</span><br>the Journey</div>
                        </div>
                        <div class="col-lg-1"></div>
                    </div>
                    <div class="d-lg-none">
                        <div class="mark-text1 oswald text-uppercase"><span class="yellow-text">Join</span> the Community</div>
                        <div class="mark-text1 oswald text-uppercase"><span class="yellow-text">Influence</span> the Journey</div>
                    </div>
                    <div class="row mt-4 mt-lg-5 justify-content-center">
                        <div class="col-lg-10">
                            <p class="fs-18 fw-normal text-white opacity-85 text-center">Step forward and be a part of the wave of knowledge and shared experiences. Join us, contribute to the community, beginning with sharing your candid university experience through our survey. Remember, your journey could illuminate someone else's path. Together, let's continue to foster a robust, nurturing space that empowers every Indian student aspiring to fly beyond horizons. </p>
                            <div class="yellow-text my-4 fw-medium fs-22">Impact the Path of Indian Students</div>
                            <div><a href="#/signup" class="btn tis-big-btn oswald">Join Now</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Loader from '@/helpers/Loader';
import store from '@/composables';

export default {
    name: 'Overseas',
    setup () {
        const { loading, init } = store();
        init();
        return {
            loading
        }
    },
    components: {
        Loader
    }
}
</script>