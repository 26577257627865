<template>
  <section class="mb-5">
    <div class="container text-center text-lg-start">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="cs-title-text fw-medium text-center minus-img">Contact<span class="orange-text">Us</span></div>
          <p class="text-center mt-4 fw-normal">Please feel free to contact us at <a href="mailto:info@theindianstudent.com" class="text-decoration-none fw-medium">info@theindianstudent.com</a> <br>We look forward to hearing from you.</p>
        </div>
      </div>
      <div class="row justify-content-center mt-0 mt-lg-5">
        <div class="col-lg-8 text-center mt-4">
          <img src="@/assets/images/contact-us.svg" alt="Masthead Banner" class="img-fluid">
        </div>
        <!-- /col -->
        <div class="col-lg-4">
            <form id="contactfrm" @submit.prevent="contactus">
                <fieldset :disabled="loading">
                    <div class="row mt-4 mt-lg-0">
                        <div class="d-flex mb-3 align-items-center">
                            <div class="sm-line">&nbsp;</div>
                            <h3 class="fw-semibold text-uppercase ms-3 blue-text">Write to Us</h3>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-4">
                                <input type="text" class="form-control" id="name" name="name" v-model="contact.name" placeholder="Your Name">
                                <label for="floatingName">Your Name</label>
                            </div>
                            <div class="error" v-if="errors.name">{{ errors.name }}</div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-4">
                                <input type="email" class="form-control" id="email" name="email" v-model="contact.email" placeholder="Email ID">
                                <label for="floatingEmail">Email ID</label>
                            </div>
                            <div class="error" v-if="errors.email">{{ errors.email }}</div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-4">
                                <input type="tel" class="form-control" id="phone" name="phone" v-model="contact.phone" placeholder="Mobile Number">
                                <label for="floatingnumber">Mobile Number</label>
                            </div>
                            <div class="error" v-if="errors.phone">{{ errors.phone }}</div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-4">
                                <textarea class="form-control" placeholder="Enter Your Message" id="message" name="message" v-model="contact.message" style="height: 100px"></textarea>
                                <label for="floatingTextarea">Your Message</label>
                            </div>
                            <div class="error" v-if="errors.message">{{ errors.message }}</div>
                        </div>
                        <div class="col-md-12">
                            <!--<a href="#" class="btn btn-yellow text-dark px-4 py-3 text-uppercase oswald" data-bs-toggle="modal" data-bs-target="#OTPModal">Send Message</a>-->
                            <button class="btn btn-yellow text-dark px-4 py-3 text-uppercase oswald">
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <span>Submit</span>
                            </button>
                        </div>
                    </div>                    
                </fieldset>
            </form>
            <div id="contactmsg" class="cs-title-text fw-medium text-center minus-img"></div>
        </div>
        <!-- /col-5 -->
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import Loader from '@/helpers/Loader.vue';

let api_url = (process.env.VUE_APP_ENV == 'local') ? process.env.VUE_APP_LOCAL_API : process.env.VUE_APP_PROD_API;

export default {
    name: 'Contactus',
    data () {
        return {
            contact: {
                name: '',
                email: '',
                phone: '',
                message: ''
            }, 
            errors: [],
            loading: false,            
        }
    },
    methods: {
        async contactus () {
            this.loading = true;
            let response = await axios.post(`${api_url}/api/contactus`, this.contact);
            if(response.data.success == false && this.errors.length > 0) {
                console.log(response.data.errors);
                this.errors.name = response.data.errors.name;
                this.errors.email = response.data.errors.email;
                this.errors.phone = response.data.errors.phone;
                this.errors.message = response.data.errors.message;

                return false;
            }
            else {
                $('#contactfrm').hide();
                $('#contactmsg').html(response.data.message);
                this.loading = false;
            }
        }
    }
}
</script>
<style>
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
</style>