import Validations from "./Validations";

export default class SignupValidations {

    //constructor(email, phone) {
    constructor(userObj) {
        //this.email = userObj.personal_email;
        this.userObj = userObj;
        //this.phone = userObj.phone;
    }

    checkValidations() {
        
        let errors = [];
        //email validation
        //if(!Validations.checkEmail(this.email).status) {
        if(!Validations.checkEmail(this.userObj).status) {
            //errors['email'] = 'Invalid Email.';
            errors['personal_email'] = Validations.checkEmail(this.userObj).msg;
        }
        //name validation
        if(!Validations.checkName(this.userObj).status) {
            //errors['phone'] = 'Invalid Phone Number.';
            errors['full_name'] = Validations.checkName(this.userObj).msg;
        }
        //gender validation
        if(!Validations.checkGender(this.userObj).status) {
            //errors['phone'] = 'Invalid Phone Number.';
            errors['gender'] = Validations.checkGender(this.userObj).msg;
        }
        //dob validation
        if(!Validations.checkDOB(this.userObj).status) {
            //errors['phone'] = 'Invalid Phone Number.';
            errors['dob'] = Validations.checkDOB(this.userObj).msg;
        }
        //phone validation
        /*
        if(!Validations.checkPhone(this.userObj, 10).status) {
            //errors['phone'] = 'Invalid Phone Number.';
            errors['phone'] = Validations.checkPhone(this.userObj, 10).msg;
        }
        */
        return errors;
    }

    checkAcademics() {
        let errors = [];
        //academic level validation
        if(!Validations.checkAcadLevel(this.userObj).status) {
            errors['degree_type'] = Validations.checkAcadLevel(this.userObj).msg;
        }
        //education status
        if(!Validations.checkDegreeStatus(this.userObj).status) {
            errors['degree_status'] = Validations.checkDegreeStatus(this.userObj).msg;
        }
        //grad year
        if(!Validations.checkGradYear(this.userObj).status) {
            errors['graduation_year'] = Validations.checkGradYear(this.userObj).msg;
        }
        //current status
        if(!Validations.checkCurrStatus(this.userObj).status) {
            errors['current_status'] = Validations.checkCurrStatus(this.userObj).msg;
        }
        //education mode
        if(!Validations.checkEduMode(this.userObj).status) {
            errors['education_mode'] = Validations.checkEduMode(this.userObj).msg;
        }
        //country of study
        if(!Validations.checkStudyCountry(this.userObj).status) {
            errors['country_of_study'] = Validations.checkStudyCountry(this.userObj).msg;
        }
        //course type
        if(!Validations.checkCourseType(this.userObj).status) {
            errors['course_type'] = Validations.checkCourseType(this.userObj).msg;
        }
        //institute name
        if(!Validations.checkInstitueName(this.userObj).status) {
            errors['institute_name'] = Validations.checkInstitueName(this.userObj).msg;
        }
        //study city
        if(!Validations.checkStuyCity(this.userObj).status) {
            errors['city_of_study'] = Validations.checkStuyCity(this.userObj).msg;
        }
        //residence country
        /*
        if(!Validations.checkResiCountry(this.userObj).status) {
            errors['residence_country'] = Validations.checkResiCountry(this.userObj).msg;
        }
        */

        return errors;
    }
 
}